import styled from 'styled-components/macro'


export const Link = styled.div`
/* color: #43425D; */
cursor:pointer;
`

export const Container = styled.div`
    background: #02025d;
    height: 100vh;
    position: fixed;
    width: ${({collapse})=> collapse ? '7%' : '15%'};
    top: 0;
    left: 0;
    bottom: 0;
    transition: width 0.5s;

    @media (max-width:1024px){
      /* width:30%; */
      transition: width 0.5s;
      width: ${({collapse})=> collapse ? '15%' : '30%'};
    }

    @media (max-width:1000px){
        /* width:27%; */
        transition: width 0.5s;
        width: ${({collapse})=> collapse ? '15%' : '27%'};
    }

    @media (max-width:768px){
        display: none;
        width: 0%;
    }

  
    
          ${Link} {
              display:${({collapse})=> collapse ? 'none' : 'block'};
          }
    

`

export const Menu =styled.div`
display: flex;
justify-content: space-between;
height: 65px;
/* padding: .5rem 1.3rem 0 2.5rem; */
padding: ${({collapse})=> collapse ? '.5rem 1.3rem 0 2.5rem' : '.5rem 1.3rem 0 1.5rem'};
align-items: center;
`

export const Humbuger = styled.div`
cursor: pointer;
`

export const Logo = styled.div`
cursor: pointer;
`

export const LinkWrapper = styled.div`
display: flex;
flex-direction: column;
padding: 2rem 0 1rem 0;
/* gap: 3.5rem; */


`
export const LinkColumn = styled.div`
display: flex;
align-items: center;
margin-bottom:.8rem;
gap: 30px;
color: #fff;
border-left: 5px solid transparent;
padding: .5rem 1.3rem .5rem 1.5rem;




&:hover{
    background: #fff;
    padding: .5rem 1.3rem .5rem 1.5rem;
    color: #00509D;
    cursor: pointer;
    svg{
        color: #00509D;
    }
}

`

export const LinkRow = styled.div`
display: flex;
flex-direction: column;


.active{
    background: #fff;
  
    border-left: 5px solid #00509D;
    cursor: pointer;
    height: 45px;
    margin-bottom: .8rem;

    >${LinkColumn}{
        color: rgb(0, 80, 157);
        border-left: 0px solid #00509D;
       
    }
    svg{
        color: #00509D;
    }

}
`

export const LinkIcon = styled.div`


   .rotate-icon{
     
        transform: rotate(45deg);
     
 }


 
`
