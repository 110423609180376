import React, {useState, useEffect} from 'react'
import { ContentWrapper, CustomModal, OnboardingWrapper } from '../../component'
import DashboardContainer from '../../container/dashboardContainer'
import FeatherIcon from 'feather-icons-react'
import {useHistory} from 'react-router-dom'
import { Button, HeaderWrapper, Input, PlayerColumn, PlayerContainer, PlayerForm, PlayerHeader } from './players-styles/players'
import { isAuthenticated } from '../../utils/data'
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { Loading } from '../../container/loading';
import { GamesContainer, GameColumn, GameDetailsWrapper,GameHeader,GameRow, GameItem   } from './games-styles/games-styles'
import { ModalColumn, ModalContent, ModalHeader, ModalRow, ModalTitle } from '../../component/custom-modal/styles/custom-modal'
import { Label } from '../../component/form/styles/form';
import moment from 'moment'




const GuessGames = () => {
  
    let history = useHistory();
    const {obj:{ token}} = isAuthenticated();
    const [showModal, setShowModal] = useState(false)
    const [allGuess, setAllGuessGames] = useState();
    const [updateCat, setUpdateCat] = useState(false);
    const [cat, setCat] = useState();
    const [viewGuessDetails, setViewGuessDetails] = useState();
    const [updateDom, setUpdateDom] = useState(false);
    const [spinner, setSpinner]  = useState(false);

    const addCategory =()=>{
        setShowModal(true);
        setUpdateCat(false);  
        setValues({...values, category:''})

    };

    const createCategory = async(e)=>{
      e.preventDefault();
      setSpinner(false) 

      if(!category){
        setSpinner(false) 
        setValues({...values, errors:{categoryError:'Name is required'}})
      }else{
        let payload ={
          name:category
        }
        try{
        const {data} = await axios.post(`${process.env.REACT_APP_API_URL}/games/guess-game-category`,
        payload,
        {
          headers:{
            "Authorization" : ` ${token}`,
            
          }
         });
  
         console.log("Guess Game Categories", data)
      
        if(data){
          setSpinner(false) 
          setShowModal(false);
          setUpdateDom(true)
          toast.success('Update was successful!');
        }
        }catch(error){
                
          if (error instanceof Error) {
            if(error.message === 'Network Error'){
              toast.error('Please check your network connection and try again!');
              setSpinner(false) 
             }else{
              toast.error('System Error!')
              setSpinner(false) 
             }
            }
          }
      }
    }

    const updateCategory = (item)=>{
       setCat(item);
        setShowModal(true);
        setUpdateCat(true);
        setValues({...values, category:item.name})
    }

    const viewGuess = (item)=>{
        setViewGuessDetails(item);
        setShowModal(true);
        setUpdateCat(true);
        setValues({...values, category:item.name})
    }

    const [values, setValues] = useState({
        category:'',
        errors:{
          categoryError:'',
        }
    
      });

    const [loading, setLoading] = useState(false);
 
    console.log('guess', viewGuessDetails)
    const getGuessGames = async () => {
 
      setSpinner(true)
      try{
      const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/games/guess-game`,{
        headers:{
          "Authorization" : ` ${token}`
        }
       });

       console.log("Guess Game", data)
    
      if(data){
        setSpinner(false)
        setAllGuessGames(data?.obj?.guessGame?.sort((a,b) => new Date(a) < new Date(b) ? 1 : -1))
        
     
      }
      }catch(error){
              
        if (error instanceof Error) {
          if(error.message === 'Network Error'){
            toast.error('Please check your network connection and try again!');
            setSpinner(false)
           }else{
            toast.error('System Error!')
            setSpinner(false)
           }
          }
        }
    } 



    const updateGuessCat = async (e) => {
      e.preventDefault();
      setSpinner(true) 

      if(!category){
        setSpinner(false) 
        setValues({...values, errors:{categoryError:'Name is required'}})
      }else{
        let payload ={
          id:cat.id,
          name:category
        }
        console.log('Update Cate',payload)
        try{
        const {data} = await axios.put(`${process.env.REACT_APP_API_URL}/games/guess-game-category`,
        payload,
        {
          headers:{
            "Authorization" : ` ${token}`
          }
         });
  
         console.log("Guess Games", data)
      
        if(data){
          setSpinner(false) 
          setShowModal(false);
          setUpdateDom(true)
          toast.success('Update was successful!');
        }
        }catch(error){
                
          if (error instanceof Error) {
            if(error.message === 'Network Error'){
              toast.error('Please check your network connection and try again!');
              setSpinner(false) 
             }else{
              toast.error('System Error!')
              setSpinner(false) 
             }
            }
          }
      }

     
    } 



    const {category, errors} = values;
    
    const handleChange = name => e=>{  

    setValues({...values, errors:{}, [name]: e.target.value})
  }
 
console.log(category)


    useEffect(()=>{

      getGuessGames()
    }, [updateDom])

    

    return (
        <DashboardContainer>



      {/* MODAL SESSION */}
        
      {showModal && (
          <>
             
                 
              <CustomModal setShowModal={setShowModal}>
                    {!viewGuessDetails && (
                         <PlayerForm style={{border: "2px solid #d3d3d3"}}>   
                         <h3>{updateCat ? 'Update Category' : 'Add Category'}</h3>
                         <OnboardingWrapper.FormGroup  >
                           <Label>Name</Label>
                             <Input type="text" value={category}  placeholder="Name" onChange={handleChange('category')} />
                             <OnboardingWrapper.Error>{errors?.categoryError}</OnboardingWrapper.Error>
                           </OnboardingWrapper.FormGroup>
                               
                            {updateCat ? <OnboardingWrapper.Button onClick={updateGuessCat}>
                           {spinner? <small>Loading...</small> : "Update "}
                           
                           </OnboardingWrapper.Button > : <OnboardingWrapper.Button onClick={createCategory} >
                           {spinner? <small>Loading...</small> : "Add "}
                           
                           </OnboardingWrapper.Button>}
                            
                             
                          </PlayerForm>
                    )}

                     {viewGuessDetails && (
                         <GameDetailsWrapper >
                             <GameHeader>Game Details</GameHeader>
                              <GameRow>
                                 <GameColumn>
                                   <GameItem>
                                     Question
                                   </GameItem>
                                   <GameItem>
                                     Answer
                                   </GameItem>

                                 </GameColumn>
                                  <GameColumn>
                                      <GameItem>{viewGuessDetails.answerQuestion}</GameItem>
                                      <GameItem>{viewGuessDetails.answerText}</GameItem>
                                  </GameColumn>
                              </GameRow>
                         </GameDetailsWrapper>
                     )}
                  
                   
             </CustomModal>  
     
             
          </>

      )}

      {/* END MODAL */}





        <ContentWrapper>
               <PlayerContainer>
                    <HeaderWrapper>
                    <PlayerHeader>
                      Guess Games
                      <div onClick={()=>history.push('/games')}><FeatherIcon icon='arrow-left' />All Games</div>
                    </PlayerHeader>
                    <Button onClick={addCategory}><FeatherIcon icon='plus' /> Add Game now</Button>
                    </HeaderWrapper>
                    <GamesContainer>
                    <div className='players-table'>

                                   {spinner && (
                                     <Loading />
                                   )}  
                                     
                                   {loading && (
                                     <Loading />
                                   )}  

                                   {!spinner && (
                                     <table className='players'>
                                     <tbody>
                                     <tr>
                                       <th>#</th>
                                       <th>Time Range</th>
                                       <th>Question</th>
                                       <th>Correct Answers</th>
                                       <th>Options</th>
                                       <th>Created Time</th>
                                       {/* <th>Update Time</th> */}
                                       
                                       <th>Action</th>
                                      
                                     </tr>
                                     
                                     {allGuess?.map((guess, i)=>(
                                       <tr key={i}>
                                       <td>{i +1}</td> 
                                      

                                       <td className='points' >
                                         {''}</td> 
                                         <td className='points'>{guess.questionText}</td>
                                         <td className='points' >
                                         {guess.answerText}</td> 
                                         <td className='points' >
                                         View Options</td> 
                                         {/* <td className='points' >
                                         {guess.answerText}</td>  */}
                                       
                                       <td>               
                                       <div className='user-avatar'>
                                       {moment(guess.createdTime).format('LLL')}
                                         </div>
                                       </td>
              
                                    <td className='points'>
                                      <div className='cat-action-btn'>
                                      <FeatherIcon icon='eye' onClick={()=>viewGuess(guess)}/>
                                        <FeatherIcon icon='edit-3' onClick={()=>updateCategory(guess)}/>
                                        <FeatherIcon icon='trash-2' />
                                      </div>
                                    </td>
                                       
                                      
                                       
                                     
                                     </tr>
                                     ))}

                                   
                                     
                                     </tbody>
                                   </table>
                                   )}   
                    </div>
                    </GamesContainer>
               </PlayerContainer>
             
        </ContentWrapper>
    <Toaster position='top-center'/>
    </DashboardContainer>

    )
}

export default GuessGames
