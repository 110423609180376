import React, {useContext, useEffect, useState, useRef} from 'react'
import { DashboardContent, Sidebar, TopBar } from '../component';
import logo from '../assets/images/biggies-logo.svg'
import avatar from '../assets/images/avatar.png'
import FeatherIcon from 'feather-icons-react'
import {isAuthenticated, signout } from '../utils/data';
import {Link, NavLink, useHistory} from 'react-router-dom'
import { Context } from '../Context';
import IdleTimer from 'react-idle-timer'
import SidebarMenu from '../component/mobile-menu.js';

const DashboardContainer = ({children, ...resProps}) => {

    const history = useHistory();
    const {state : {user}, dispatch} = useContext(Context);

    const {obj:{token}} = isAuthenticated();
    const [toggle, setToggle] = useState(false);


    const [collapse, setCollapse] = useState(false)
    const timeOutRef = useRef()


    const logout = () => {

        // const {data}  =  axios.post(`https://backend.uatgiveraffle.xyz/api/v1/logout`, {
        //         headers:{
        //             "Authorization" : `Bearer ${plainTextToken}`
        //         }
        // })
        // console.log(data)

        dispatch({
          type:'LOGOUT',
        
        })
        signout(()=>{    
          history.push('/');
        });     
     }

     const handleIdle = ()=>{
        
         signout(()=>{    
                history.push('/');
                dispatch({
                        type:'LOGOUT',
                      
                      })
              }); 
     }

     
    return (
       <div>

        <SidebarMenu setToggle={setToggle} toggle={toggle} />
         <div styles={{display: "flex"}}>
            <Sidebar  collapse={collapse}>
                <Sidebar.Menu>
                   <Sidebar.Logo>
                    <Link to='/dashboard'> <img src={logo} width='100px' alt="logo"/></Link>
                    </Sidebar.Logo>
                  {/* {!collapse && (
                            <Sidebar.Humbuger  onClick={()=>setCollapse(!collapse)}><FeatherIcon icon='menu' color="#fff"  size="30px" /></Sidebar.Humbuger>
                  )} */}
                    
                </Sidebar.Menu>
                <Sidebar.LinkWrapper>
                    <Sidebar.LinkRow>
                        <NavLink to='/dashboard' activeClassName='active'>
                        <Sidebar.LinkColumn >
                                <Sidebar.LinkIcon><FeatherIcon icon='home'  /></Sidebar.LinkIcon>
                                <Sidebar.Link>Dashboard</Sidebar.Link>
                        </Sidebar.LinkColumn>
                        </NavLink>
                        <NavLink to='/games' activeClassName='active'>
                        <Sidebar.LinkColumn>
                                <Sidebar.LinkIcon><FeatherIcon icon='github'  /></Sidebar.LinkIcon>
                                <Sidebar.Link>Games</Sidebar.Link>
                        </Sidebar.LinkColumn>
                        </NavLink>
                        <NavLink to='/players' activeClassName='active'>
                        <Sidebar.LinkColumn>
                                <Sidebar.LinkIcon><FeatherIcon icon='users' /></Sidebar.LinkIcon>
                                <Sidebar.Link>Users</Sidebar.Link>
                        </Sidebar.LinkColumn>
                        </NavLink>
                        
                    </Sidebar.LinkRow>
                    <Sidebar.LinkRow>
                      {/* <NavLink to='/reports' activeClassName='active'>
                        <Sidebar.LinkColumn>
                                <Sidebar.LinkIcon><FeatherIcon icon='send'  /></Sidebar.LinkIcon>
                                <Sidebar.Link>Reports</Sidebar.Link>
                        </Sidebar.LinkColumn>
                        </NavLink> */}
                        <NavLink to='/rewards' activeClassName='active'>
                        <Sidebar.LinkColumn>
                                <Sidebar.LinkIcon><FeatherIcon icon='activity'  /></Sidebar.LinkIcon>
                                <Sidebar.Link>Rewards</Sidebar.Link>
                        </Sidebar.LinkColumn>
                        </NavLink>
                        <NavLink to='/transactions' activeClassName='active'>
                        <Sidebar.LinkColumn>
                                <Sidebar.LinkIcon><FeatherIcon icon='credit-card' /></Sidebar.LinkIcon>
                                <Sidebar.Link>Deposit</Sidebar.Link>
                        </Sidebar.LinkColumn>
                        </NavLink>
                        <NavLink to='/reserve' activeClassName='active'>
                        <Sidebar.LinkColumn>
                                <Sidebar.LinkIcon><FeatherIcon icon='shopping-bag' /></Sidebar.LinkIcon>
                                <Sidebar.Link>Reserve</Sidebar.Link>
                        </Sidebar.LinkColumn>
                        </NavLink>
                      <NavLink to='/settings' activeClassName='active'>
                        <Sidebar.LinkColumn>
                                <Sidebar.LinkIcon><FeatherIcon icon='settings'  /></Sidebar.LinkIcon>
                                <Sidebar.Link>Settings</Sidebar.Link>
                        </Sidebar.LinkColumn>
                        </NavLink> 
                        <NavLink to='/profile' activeClassName='active'>
                        <Sidebar.LinkColumn>
                                <Sidebar.LinkIcon><FeatherIcon icon='user'  /></Sidebar.LinkIcon>
                                <Sidebar.Link>Profile</Sidebar.Link>
                        </Sidebar.LinkColumn>
                        </NavLink> 
                        
                    </Sidebar.LinkRow>
                    <Sidebar.LinkRow>
                        <Sidebar.LinkColumn>
                                <Sidebar.LinkIcon><FeatherIcon icon='log-out'  /></Sidebar.LinkIcon>
                                <Sidebar.Link onClick={logout}>Logout</Sidebar.Link>
                        </Sidebar.LinkColumn>
                        
                    </Sidebar.LinkRow>
                </Sidebar.LinkWrapper>
            </Sidebar>
            <IdleTimer ref={timeOutRef}  timeout={5 * 100000} onIdle={handleIdle}>
            <DashboardContent  {...resProps}   collapse={collapse}>
             <TopBar> 
                 <div className='mobile-logo'><FeatherIcon icon='menu' color="#b3b3b3"  size="30px" onClick={()=>setToggle(true)} />
                   <div> <img src={logo} width='90px' alt="logo"/></div>
                 </div>
                   
                        
                   
                           <div className='collapse-icon' onClick={()=>setCollapse(!collapse)}><FeatherIcon icon='menu' color="#fff"  size="30px" /></div>
                   
                   <div className='topbar-icon'>
                       {/* <TopBar.Search type="text" placeholder="Search" /> */}
                <FeatherIcon icon='bell' fill='' color="#fff" size='20px' />
              <div><img src={avatar} width="35px" /></div></div>
             </TopBar>
             {children}
            </DashboardContent>
            </IdleTimer>

            
        </div>
       </div>
    )
}

export default DashboardContainer
