import styled from 'styled-components/macro'


export const PlayerContainer = styled.div`
display: flex;
flex-direction: column;
width: 100%;
/* padding-top: 2rem; */
gap: 10px;

.players-table{
    background: #fff;
    padding: 0 1rem;
}
.players {
   
   th, td{
    height: 45px;
    color: #111;
    a svg {
       color:#111;
   }
    }
}
tr:nth-child(even) {
    /* background-color: #f2f2f2; */
   height: 40px;
  /* background: #7a7a7a1f; */
  border-bottom:1px solid #00509d;
  a svg {
       /* color:#fff; */
   }
   td{
    /* color: #111; */
    }
}


@media (max-width:600px){
    padding-top: 0rem;
}


`

export const PlayerHeader = styled.div`
   font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
   div{
      font-size:.9rem;
      color: #797979;
      display: flex;
      align-items: center;
      gap:.2rem;
      cursor: pointer;
   }


    @media (max-width:600px){
        margin-bottom: 0rem;
        font-size: 1.2rem;
    }
   
`

export const PlayerRow = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap:wrap;

`

export const PlayerColumn = styled.div`
    flex: 1 1 17%;
     background: ${({bgColor})=>bgColor ? bgColor : ' #0581b6'}; 
     /* background: #ed9717; */
    padding: .5rem;
    height: ${({height})=>height ? height : '  130px'}; 
    border-radius: 7px;
    box-shadow: 1px 1px 4px 4px rgb(0 0 0 / 10%);
    
    th{
        color: #000;
    }
    .leaderboard{
        padding: 1rem;
      
    .view-more-box{
        display: flex;
       justify-content: flex-end;
    }
       .view-more a{
    display: flex;
    align-items: center;
    gap: 10px;
    background: #0581b6;
    height: 35px;
    padding: 0 .5rem;
    color: #fff;
    margin-top:1rem;
    width: 120px;
    border-radius: 8px;
   
    }
     
    }

   

`

export const PlayerInner = styled.div`  
    font-size: 1.5rem;
    padding: .5rem ;
    display: flex;
    justify-content: space-between;
    align-items:center;
   
`

export const PlayerTitle = styled.div`
    font-size: ${({size})=>size? size : '1.3rem'};
    font-weight:${({weight})=>weight? weight : '400'};
    color: ${({color})=>color ? '#111' : '#fff'}; 
    span{
        font-family:Arial, Helvetica, sans-serif;
    }
    
   @media (max-width:600px){
    font-size: ${({size})=>size? '1.5rem' : '1rem'};
   } 
   
`
export const PlayerIcon= styled.div`
    display: flex;
    background: #fff;
    color: #111;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 50%; 
    
   
`

export const Small = styled.div`
font-size:.8rem;
color:#fff;

`

export const TableContainer = styled.div`
/* margin-top:1.3rem; */
overflow-x: scroll;

.points{
color: #06a9b5;
font-weight: 600;
}
.user-avatar{
    display: flex;
    align-items: center;
    gap: 10px;
}
table {
    width:100%;
    background: #fff;
    th{
        background: #00509d;
        color:#fff;
        font-size:.9rem;
        font-weight:normal;
        border: 1px solid #d3d3d354 !important;
        text-align: center;
    }
    tr{
        border: 1px solid #00509d !important;  
       
        img{
            width: 60px;
            object-fit: scale-down;
            height: 60px;
        } 
    }
    td{
        text-align:center;
        border: 1px solid #d3d3d354 !important;
    }
}
`

export const HeaderWrapper = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
`

export const Button = styled.button`
display: flex;
color: #ffffff;
align-items: center;
/* background: ${({bgcolor})=> bgcolor ? bgcolor : '#00509d'}; */
background: ${({disabled})=> disabled ? '#00509da3' : '#00509d'};
border: 0;
height: 40px;
width:${({width})=>width};
min-width: 130px;
justify-content: center;
border-radius: 5px;
font-size: 1rem;
cursor: ${({disabled})=>disabled ? 'not-allowed' : 'pointer'}; 
padding: ${({bgcolor})=> bgcolor ? '10px 20px' : 0 };
border :1px solid transparent ;
    &:hover{
    color: ${({bgcolor})=> bgcolor ? bgcolor : '#02025d' };
    align-items: center;
    background:  #ffffff; 
    border : 1px solid ${({bgcolor})=> bgcolor ? bgcolor : '#02025d' } ;
    }

`

export const PlayerForm = styled.form`

    width: 400px;
   
    /* height: 400px; */
    /* margin: auto; */
    /* border: 2px solid #fff; */
    padding:  ${({padding})=>padding ? padding :'2rem'};
    border-radius: 10px;
    background: #fff;
    .delete-cat-info{
        margin:auto;
        text-align:center;
    }

    @media (max-width:600px){
        padding: 3rem 2rem;
    border-radius: 10px;
    background: #fff;
    width: auto;
    }

`

export const Input = styled.input`
width: 100%;
height: 50px;
border: 1px solid #00509d;
box-sizing: border-box;
border-radius: 10px;
background: #ebf5ff;
padding: 0 1rem;
font-size: 14px;
outline: none;
`
export const Label = styled.div`
font-size: .8rem;
font-weight: 600;
padding-bottom: .4rem;
`

export const Select = styled.select`
    width: ${({width}) => width ? width : '100%'};
    height: ${({height}) => height ? height :'50px'};
    border: 1px solid #00509d;
    box-sizing: border-box;
    border-radius: 10px;
    background: #ebf5ff;
    padding: 0 1rem;
    font-size: 14px;
    outline: none;

`

export const PlayerSearch = styled.input`
    width: 28%;
    height: 50px;
    border: 1px solid #b9d2de;
    box-sizing: border-box;
    border-radius: 10px;
    background: #ebf5ff;
    padding: 0 1rem;
    font-size: 14px;
    outline: none;
    width: 300px;


    @media (max-width:600px){
        width: 50%; 
    }
`
export const SumTotals = styled.div`
    display: flex;
    justify-content: space-between;
    gap:30px;

`

export const UserStat = styled.div`
    display: flex;
    justify-content: space-between;
    gap:30px;

`
export const UserStatBox = styled.div`
    background: #fff;
    width: 100%;
    max-width: 800px;
    color: #111;
    padding: 2rem;
    border-radius: 10px;

`

export const UserStatRow = styled.div`
    display: flex;
    justify-content: space-between;
    /* border-top: 2px solid #00509d; */
    border-bottom: 2px solid #84858633;

    padding: 1rem;
    /* border-radius: 10px; */
    /* background: #d8d8d836; */
    margin-bottom: 1rem;
    &:last-of-type{
        margin-bottom:0;
    }

`

export const UserStatTotal = styled.div`
display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: #fff;
    background: #00509d;
    box-shadow: 0 0 1px 1px rgb(0 0 0 / 0%);
    border-radius: 10px;
    width:50%;

`

export const PlayerCard = styled.div`
  
  margin-bottom: 20%;
    transition: 0.3s;
    width:${props => props.width ? props.width : '100%'};
   
    background: #02025d30;
    border-radius: 5px;
    
`
export const CardContainer = styled.div`
    ${'' /* padding: 50px 30px; */}
    padding: ${props=>props.padding ? props.padding :'22px 23px'};
   

`
export const FilterUsers = styled.select`
    width: 250px;
    height: 50px;
    border-radius: 10px;
    padding: 0px 1rem;
    font-size: 14px;
    border: none;
    background: #fff;
    outline:none;
    font-size:1rem;
  

`
export const Depo = styled.div`
    background: #02025d;
    padding: ${props => props.padding ? props.padding : '47px 0'};
    border-radius: 5px;
    color: #ffffff;
`


