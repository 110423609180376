import React, {useState, useEffect} from 'react'
import { ContentWrapper } from '../../component'
import DashboardContainer from '../../container/dashboardContainer';
import axios from 'axios'
import FeatherIcon from 'feather-icons-react'
import {useHistory, Link} from 'react-router-dom'
import toast, {Toaster} from 'react-hot-toast'
import { OverviewColumn, OverviewHeader, OverviewContainer, OverviewInner, OverviewRow, OverviewTitle } from './user-dashboard-styles/user-dashboard-styles';
import { TableContainer } from './players-styles/players';
import { Button, HeaderWrapper, PlayerColumn, PlayerContainer, PlayerHeader, PlayerSearch } from './players-styles/players'
import reward from '../../assets/images/reward-icon.png'



const Rewards = () => {
    const [loading, setLoading] = useState(false)

    // const [imageFile, setImgFile] = useState()
    // const [preview, setPreviewImage] = useState();
    // const [loading, setLoading] = useState()

    //  const handleImageUpload = async(e)=>{

    //     let imageFile = e.target.files[0];

    //     setImageFile(imageFile)

    //     setPreviewImage(window.URL.createObjectURL(imageFile) || window.webkitURL.createObjectURL(imageFile));
    //     setLoading(true)

      
    //   if(e.target.files[0].size > 1000000){
   
    //     setLoading(false);
    //     toast.error(`File size should be less than 1MB`);
       
    //    }else{
    //     const imageData = new FormData();
    //     imageData.append("image", imageFile);

    //     try{
    //         let {data} =  await axios.post(`https://upload-files-image-cfx55lkleq-uc.a.run.app/uploadImages`, imageData,
    //         {
                
    //          onUploadProgress: (e)=>{
    //           setUploadProgress(Math.round((100 * e.loaded) /e.total))
    //          }
    //         }
    //         );
        
    //           console.log('RESPONSE', data)
    //           setLoading(false);
           
    //           setImage(data);
        
              
    //       }catch(err){
    //         console.log(err)
    //         setLoading(false)
    //         toast.error('Image Upload failed, Try again');
    //       }
        

    //    }
      

    //   }


// guess-game-category

    return (
        <DashboardContainer>  
             <ContentWrapper>
               <PlayerContainer>
               {/* <OverviewContainer> */}
             <OverviewHeader>Rewards</OverviewHeader>
                 <OverviewRow  >
                     <OverviewColumn display='flex' bgColor='#fff' alignItem='center' centerContents='center' height='300px' flexWidth='0 0 35%'> 
                         <Link to='/manage-rewards'>
                            <OverviewInner>     
                            <img  src={reward}  alt='reward-icon' width='50px'/>   <OverviewTitle size='1.5rem' style={{color:'#00509d'}}>
                                Manage Rewards
                             </OverviewTitle>
                         </OverviewInner>
                         </Link>
                        
                     </OverviewColumn>

                     <OverviewColumn display='flex' bgColor='#fff' alignItem='center' centerContents='center'  height='300px' flexWidth='0 0 35%'>
                         <OverviewInner>
                         <img  src={reward}  alt='reward-icon' width='50px'/> 
                             <OverviewTitle size='1.5rem' style={{color:'#00509d'}} >
                             Manage Winnings
                             </OverviewTitle>
                         </OverviewInner>
                         
                     </OverviewColumn>
                   
                 </OverviewRow>
                 <TableContainer></TableContainer>
             {/* </OverviewContainer> */}
                    {/* <RewardsDiv>
                    <div style={{display: 'flex', columnGap: '20px'}}>
                        <Button bgcolor="#FA8833">Coin redemption</Button>
                        <Button bgcolor="#A3A3A3">Point redemption</Button>
                    </div>
                    <DataSearchContainer>
                    <DataSearch placeholder='Search user or account number' />

                        <div style={{position: 'absolute', top: '25%', left: 10}}>
                        <FeatherIcon icon="search" size="21px" color="gray"  />
                        </div>
                    </DataSearchContainer>
                    </RewardsDiv> */}
                    {/* <TabHeader style={{overflowx: 'scroll'}}>
                        <div style={{fontSize: '13px', padding: '7px 15px', borderRadius: '5px', border: '1px solid #949494', display: 'flex', alignItems: 'center'}}>Notify <FeatherIcon icon="chevron-down" color="gray" /> </div>
                           <div className="tab-list" style={{display: 'inline-flex', margin: '0 auto', columnGap: '10px'}}>
                           <TabList minw pad notbold>Qualified</TabList>
                           <TabList minw pad notbold activeClass="active">Notified</TabList>
                           <TabList minw pad notbold>Responded</TabList>
                           <TabList minw pad notbold>Processed</TabList>
                           <TabList minw pad notbold>Paid Out</TabList>
                           </div>
                           <div style={{fontSize: '13px',padding: '7px 15px', borderRadius: '5px', border: '1px solid #949494', display: 'flex', alignItems: 'center'}}>All dates <FeatherIcon icon="chevron-down" color="gray" /></div>
                    </TabHeader>  */}
{/* 
                    <TabContent>
                    <div className='players-table'>
                                   {loading && (
                                     <Loading />
                                   )}  
                                     <table className='players'>
                                     <tbody>
                                     <tr>
                                       <th>
                                           <CheckBox />
                                        </th>
                                       <th>TIME</th>
                                       <th>DATE</th>
                                       <th>CAMPAIGN TAG</th>
                                       <th>NO OF PLAYERS</th>
                                       <th>TOTAL POINTS</th>
                                       <th>NAIRA VALUE</th>
                                       <th>WINNING CRITERIA</th>
                                       <th>STATUS</th>
                                     </tr>
                                     <tr>
                                         <td>
                                         <CheckBox />
                                         </td>
                                     <td>
                                     09:18:54
                                     </td> 
                                       <td className='points'>
                                           SEPT 10
                                        </td>
                                       <td>
                                       
                                       <div className='points'>
                                           210207175233TRD8
                                        </div>
                                       </td>
                                       <td className='points'>
                                       100,000
                                       </td>
                                        <td className='points'>
                                        3,000,000
                                        </td>
                                        <td className='points'>
                                            15,000
                                        </td>
                                        <td className='points'>Deposits</td>
                                        <td className='points'>Running</td>
                                     </tr>
                                     <tr>
                                     <td>
                                         <CheckBox />
                                         </td>
                                     <td>
                                     09:18:54
                                     </td> 
                                       <td className='points'>
                                           SEPT 10
                                        </td>
                                       <td>
                                       
                                       <div className='points'>
                                           210207175233TRD8
                                        </div>
                                       </td>
                                       <td className='points'>
                                       100,000
                                       </td>
                                        <td className='points'>
                                        3,000,000
                                        </td>
                                        <td className='points'>
                                            15,000
                                        </td>
                                        <td className='points'>Deposits</td>
                                        <td className='points'>Running</td>
                                     </tr>
                                     <tr>
                                     <td>
                                         <CheckBox />
                                         </td>
                                     <td>
                                     09:18:54
                                     </td> 
                                       <td className='points'>
                                           SEPT 10
                                        </td>
                                       <td>
                                       
                                       <div className='points'>
                                           210207175233TRD8
                                        </div>
                                       </td>
                                       <td className='points'>
                                       100,000
                                       </td>
                                        <td className='points'>
                                        3,000,000
                                        </td>
                                        <td className='points'>
                                            15,000
                                        </td>
                                        <td className='points'>Deposits</td>
                                        <td className='points'>Running</td>
                                     </tr>
                                     <tr>
                                     <td>
                                         <CheckBox />
                                         </td>
                                     <td>
                                     09:18:54
                                     </td> 
                                       <td className='points'>
                                           SEPT 10
                                        </td>
                                       <td>
                                       
                                       <div className='points'>
                                           210207175233TRD8
                                        </div>
                                       </td>
                                       <td className='points'>
                                       100,000
                                       </td>
                                        <td className='points'>
                                        3,000,000
                                        </td>
                                        <td className='points'>
                                            15,000
                                        </td>
                                        <td className='points'>Deposits</td>
                                        <td className='points'>Running</td>
                                     </tr>
                                     <tr>
                                     <td>
                                         <CheckBox />
                                         </td>
                                     <td>
                                     09:18:54
                                     </td> 
                                       <td className='points'>
                                           SEPT 10
                                        </td>
                                       <td>
                                       
                                       <div className='points'>
                                           210207175233TRD8
                                        </div>
                                       </td>
                                       <td className='points'>
                                       100,000
                                       </td>
                                        <td className='points'>
                                        3,000,000
                                        </td>
                                        <td className='points'>
                                            15,000
                                        </td>
                                        <td className='points'>Deposits</td>
                                        <td className='points'>Running</td>
                                     </tr>
                                     <tr>
                                     <td>
                                         <CheckBox />
                                         </td>
                                     <td>
                                     09:18:54
                                     </td> 
                                       <td className='points'>
                                           SEPT 10
                                        </td>
                                       <td>
                                       
                                       <div className='points'>
                                           210207175233TRD8
                                        </div>
                                       </td>
                                       <td className='points'>
                                       100,000
                                       </td>
                                        <td className='points'>
                                        3,000,000
                                        </td>
                                        <td className='points'>
                                            15,000
                                        </td>
                                        <td className='points'>Deposits</td>
                                        <td className='points'>Running</td>
                                     </tr>
                                     <tr>
                                     <td>
                                         <CheckBox />
                                         </td>
                                     <td>
                                     09:18:54
                                     </td> 
                                       <td className='points'>
                                           SEPT 10
                                        </td>
                                       <td>
                                       
                                       <div className='points'>
                                           210207175233TRD8
                                        </div>
                                       </td>
                                       <td className='points'>
                                       100,000
                                       </td>
                                        <td className='points'>
                                        3,000,000
                                        </td>
                                        <td className='points'>
                                            15,000
                                        </td>
                                        <td className='points'>Deposits</td>
                                        <td className='points'>Running</td>
                                     </tr>
                                     <tr>
                                     <td>
                                         <CheckBox />
                                         </td>
                                     <td>
                                     09:18:54
                                     </td> 
                                       <td className='points'>
                                           SEPT 10
                                        </td>
                                       <td>
                                       
                                       <div className='points'>
                                           210207175233TRD8
                                        </div>
                                       </td>
                                       <td className='points'>
                                       100,000
                                       </td>
                                        <td className='points'>
                                        3,000,000
                                        </td>
                                        <td className='points'>
                                            15,000
                                        </td>
                                        <td className='points'>Deposits</td>
                                        <td className='points'>Running</td>
                                     </tr>
                                     <tr>
                                     <td>
                                         <CheckBox />
                                         </td>
                                     <td>
                                     09:18:54
                                     </td> 
                                       <td className='points'>
                                           SEPT 10
                                        </td>
                                       <td>
                                       
                                       <div className='points'>
                                           210207175233TRD8
                                        </div>
                                       </td>
                                       <td className='points'>
                                       100,000
                                       </td>
                                        <td className='points'>
                                        3,000,000
                                        </td>
                                        <td className='points'>
                                            15,000
                                        </td>
                                        <td className='points'>Deposits</td>
                                        <td className='points'>Running</td>
                                     </tr>
                                     <tr>
                                     <td>
                                         <CheckBox />
                                         </td>
                                     <td>
                                     09:18:54
                                     </td> 
                                       <td className='points'>
                                           SEPT 10
                                        </td>
                                       <td>
                                       
                                       <div className='points'>
                                           210207175233TRD8
                                        </div>
                                       </td>
                                       <td className='points'>
                                       100,000
                                       </td>
                                        <td className='points'>
                                        3,000,000
                                        </td>
                                        <td className='points'>
                                            15,000
                                        </td>
                                        <td className='points'>Deposits</td>
                                        <td className='points'>Running</td>
                                     </tr>
                                     </tbody>
                                   </table>
                    </div>
                    </TabContent>

                    <TabContent>
                    <div className='players-table'>
                                   {loading && (
                                     <Loading />
                                   )}  
                                     <table className='players'>
                                     <tbody>
                                     <tr>
                                     <th>
                                         <CheckBox />
                                         </th>
                                       <th>TIME</th>
                                       <th>DATE</th>
                                       <th>PLAYER</th>
                                       <th>POINTS REDEEMED</th>
                                       <th>NAIRA VALUE</th>
                                       <th>PRIZE TYPE</th>
                                       <th>EMAIL</th>
                                       <th>STATUS</th>
                                     </tr>
                                     <tr>
                                     <td>
                                         <CheckBox />
                                         </td>
                                     <td>
                                     09:18:54
                                     </td> 
                                       <td className='points'>
                                           SEPT 10
                                        </td>
                                       <td>
                                       
                                       <div className='points'>
                                           210207175233TRD8
                                        </div>
                                       </td>
                                       <td className='points'>
                                       100,000
                                       </td>
                                        <td className='points'>
                                        3,000,000
                                        </td>
                                        <td className='points'>
                                            15,000
                                        </td>
                                        <td className='points'>Deposits</td>
                                        <td className='points'>Running</td>
                                     </tr>
                                     <tr>
                                     <td>
                                         <CheckBox />
                                         </td>
                                     <td>
                                     09:18:54
                                     </td> 
                                       <td className='points'>
                                           SEPT 10
                                        </td>
                                       <td>
                                       
                                       <div className='points'>
                                           210207175233TRD8
                                        </div>
                                       </td>
                                       <td className='points'>
                                       100,000
                                       </td>
                                        <td className='points'>
                                        3,000,000
                                        </td>
                                        <td className='points'>
                                            15,000
                                        </td>
                                        <td className='points'>Deposits</td>
                                        <td className='points'>Running</td>
                                     </tr>
                                     <tr>
                                     <td>
                                         <CheckBox />
                                         </td>
                                     <td>
                                     09:18:54
                                     </td> 
                                       <td className='points'>
                                           SEPT 10
                                        </td>
                                       <td>
                                       
                                       <div className='points'>
                                           210207175233TRD8
                                        </div>
                                       </td>
                                       <td className='points'>
                                       100,000
                                       </td>
                                        <td className='points'>
                                        3,000,000
                                        </td>
                                        <td className='points'>
                                            15,000
                                        </td>
                                        <td className='points'>Deposits</td>
                                        <td className='points'>Running</td>
                                     </tr>
                                     <tr>
                                     <td>
                                         <CheckBox />
                                         </td>
                                     <td>
                                     09:18:54
                                     </td> 
                                       <td className='points'>
                                           SEPT 10
                                        </td>
                                       <td>
                                       
                                       <div className='points'>
                                           210207175233TRD8
                                        </div>
                                       </td>
                                       <td className='points'>
                                       100,000
                                       </td>
                                        <td className='points'>
                                        3,000,000
                                        </td>
                                        <td className='points'>
                                            15,000
                                        </td>
                                        <td className='points'>Deposits</td>
                                        <td className='points'>Running</td>
                                     </tr>
                                     <tr>
                                     <td>
                                         <CheckBox />
                                         </td>
                                     <td>
                                     09:18:54
                                     </td> 
                                       <td className='points'>
                                           SEPT 10
                                        </td>
                                       <td>
                                       
                                       <div className='points'>
                                           210207175233TRD8
                                        </div>
                                       </td>
                                       <td className='points'>
                                       100,000
                                       </td>
                                        <td className='points'>
                                        3,000,000
                                        </td>
                                        <td className='points'>
                                            15,000
                                        </td>
                                        <td className='points'>Deposits</td>
                                        <td className='points'>Running</td>
                                     </tr>
                                     <tr>
                                     <td>
                                         <CheckBox />
                                         </td>
                                     <td>
                                     09:18:54
                                     </td> 
                                       <td className='points'>
                                           SEPT 10
                                        </td>
                                       <td>
                                       
                                       <div className='points'>
                                           210207175233TRD8
                                        </div>
                                       </td>
                                       <td className='points'>
                                       100,000
                                       </td>
                                        <td className='points'>
                                        3,000,000
                                        </td>
                                        <td className='points'>
                                            15,000
                                        </td>
                                        <td className='points'>Deposits</td>
                                        <td className='points'>Running</td>
                                     </tr>
                                     <tr>
                                     <td>
                                         <CheckBox />
                                         </td>
                                     <td>
                                     09:18:54
                                     </td> 
                                       <td className='points'>
                                           SEPT 10
                                        </td>
                                       <td>
                                       
                                       <div className='points'>
                                           210207175233TRD8
                                        </div>
                                       </td>
                                       <td className='points'>
                                       100,000
                                       </td>
                                        <td className='points'>
                                        3,000,000
                                        </td>
                                        <td className='points'>
                                            15,000
                                        </td>
                                        <td className='points'>Deposits</td>
                                        <td className='points'>Running</td>
                                     </tr>
                                     <tr>
                                     <td>
                                         <CheckBox />
                                         </td>
                                     <td>
                                     09:18:54
                                     </td> 
                                       <td className='points'>
                                           SEPT 10
                                        </td>
                                       <td>
                                       
                                       <div className='points'>
                                           210207175233TRD8
                                        </div>
                                       </td>
                                       <td className='points'>
                                       100,000
                                       </td>
                                        <td className='points'>
                                        3,000,000
                                        </td>
                                        <td className='points'>
                                            15,000
                                        </td>
                                        <td className='points'>Deposits</td>
                                        <td className='points'>Running</td>
                                     </tr>
                                     <tr>
                                     <td>
                                         <CheckBox />
                                         </td>
                                     <td>
                                     09:18:54
                                     </td> 
                                       <td className='points'>
                                           SEPT 10
                                        </td>
                                       <td>
                                       
                                       <div className='points'>
                                           210207175233TRD8
                                        </div>
                                       </td>
                                       <td className='points'>
                                       100,000
                                       </td>
                                        <td className='points'>
                                        3,000,000
                                        </td>
                                        <td className='points'>
                                            15,000
                                        </td>
                                        <td className='points'>Deposits</td>
                                        <td className='points'>Running</td>
                                     </tr>
                                     <tr>
                                     <td>
                                         <CheckBox />
                                         </td>
                                     <td>
                                     09:18:54
                                     </td> 
                                       <td className='points'>
                                           SEPT 10
                                        </td>
                                       <td>
                                       
                                       <div className='points'>
                                           210207175233TRD8
                                        </div>
                                       </td>
                                       <td className='points'>
                                       100,000
                                       </td>
                                        <td className='points'>
                                        3,000,000
                                        </td>
                                        <td className='points'>
                                            15,000
                                        </td>
                                        <td className='points'>Deposits</td>
                                        <td className='points'>Running</td>
                                     </tr>
                                     </tbody>
                                   </table>
                    </div>
                    </TabContent> */}

                    {/* < ReactPaginate
                        previousLabel={"previous" }
                        nextLabel={ "next" }
                        breakLabel={ "..." }
                        breakClassName={ "break-me" }
                        pageCount={ pageCount }
                        onPageChange={ handlePageClick }
                        containerClassName={ "pagination" }
                        subContainerClassName={ "pages pagination" }
                        activeClassName={ "active" } /> */}
               </PlayerContainer>
              
        </ContentWrapper>

             <Toaster position='bottom-center' />
        </DashboardContainer>
    )
}

export default Rewards
