import React, {useState, useContext} from 'react'
import logo from '../../assets/images/biggies-logo.svg'
import {OnboardingWrapper} from '../../component';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import {authenticate} from '../../utils/data';
import toast, { Toaster } from 'react-hot-toast';
import { Context } from '../../Context';


const ForgotPassword = () => {


  const history = useHistory();

  const {state, dispatch} = useContext(Context);
  const [screen, setScreen] = useState(1)

  const [values, setValues] = useState({
    opt:'',
    email:'',
    password:'',

    errors:{
      emailError:'',
      passwordError:'',
      optError:''
    }

  });

  const [loading, setLoading] = useState(false);

  const {email, password, otp, errors} = values;

    const handleChange = name => e=>{  

    setValues({...values, errors:{}, [name]: e.target.value})
  }


  const changePass = async (e)=>{
    e.preventDefault();
    
    setLoading(true);
    console.log('Values', values);
    if(!otp){
      setValues({...values, errors:{otpError:"OTP is required"}})
      setLoading(false);
    }
     else if(!email){
       setValues({...values, errors:{emailError:"Email is required"}})
       setLoading(false);

   }else if (!password){
    setValues({...values, errors:{passwordError:"Password is required"}})
    setLoading(false);
   }
   else if (password.length < 8){
    setValues({...values, errors:{passwordError:"Password must be 8 digit character or more"}})
    setLoading(false);
   }
   else{

     try{
       
       let payload={ 
           otp:otp,      
           email:email,     
           password:password   
          
         }


         console.log("PAYLOAD", payload)
     
        const {data}  = await axios.post(`${process.env.REACT_APP_API_URL}/user/auth/reset-password`, payload)
         console.log("RESPONSE", data)

          if(data?.status ===1){
          
           toast.error(data.desc);
           setLoading(false)
         
           
          }else{
            toast.success("Password Changed Successfully!");
          
          history.push('/login')
           setLoading(false)
         
          }
  
       }catch(error){

        if (error instanceof Error) {
          if(error.message === 'Network Error'){
            toast.error('Please check your network connection!');
            setLoading(false)  
           }else{
            // toast.error(error.response.desc);
           setLoading(false)
           }
    
         }else{
          // toast.error(error.response.desc);
         }
       }

    }
    

  }

  
  const resetPasswordForm = async(e)=>{

    e.preventDefault();

    setLoading(true);
    console.log('Values', values)
     if(!email){
       setValues({...values, errors:{emailError:"Email is required"}})
       setLoading(false);

   }else{

     try{
       
       let payload={   
          email:email,     
              
          
         }


         console.log("PAYLOAD", payload)
     
        const {data}  = await axios.post(`${process.env.REACT_APP_API_URL}/user/auth/reset`, payload)
         console.log("RESPONSE", data)

          if(data){
           setValues({
             email:'',
            
           });

           toast.success("6 digits code has been send your email to reset your password!");
          
           setScreen(2)
           setLoading(false)
         
          }
  
       }catch(error){
        if (error.response) {
         
             if(error.response.status === 401){
             
             }
             if(error.response.status === 400){
              toast.error(error.response.data.message);

             }else{
              toast.error(error.response.data.message);
             }
          setLoading(false)
      } else if (error.request) {
         
          console.log(error.request);
          setLoading(false)
      } else {
          
        
          toast.error(error.message);
          setLoading(false)
      }
       }

    }
    

 }
    return (
      <>
       
         <OnboardingWrapper>

             <OnboardingWrapper.Row>
              
                 <OnboardingWrapper.Column>
                    
                     <OnboardingWrapper.Form>
                         <OnboardingWrapper.ImgWrapper> <img src={logo}  alt='logo' width='120px' /> </OnboardingWrapper.ImgWrapper>
                          {screen ===1 && (
                            <>
                            <OnboardingWrapper.Title>Forgot Password? </OnboardingWrapper.Title>
                       <OnboardingWrapper.FormGroup >
                        
                        <OnboardingWrapper.Input type="email"  placeholder="Email address" onChange={handleChange('email')} />
                        <OnboardingWrapper.Error>{errors?.emailError}</OnboardingWrapper.Error>
                      </OnboardingWrapper.FormGroup>
                     
                      <OnboardingWrapper.FormGroup>
                        <OnboardingWrapper.ResetPass><Link to='/login'>Back to Login</Link></OnboardingWrapper.ResetPass>
                        </OnboardingWrapper.FormGroup>
                
                       <OnboardingWrapper.Button onClick={resetPasswordForm}>{loading? <small>Loading...</small> : "Reset Password"}</OnboardingWrapper.Button>
                       
                            </>
                          )}

                   {screen ===2 && (
                            <>
                            <OnboardingWrapper.Title>Create New Password? </OnboardingWrapper.Title>

                    <OnboardingWrapper.FormGroup >
                        
                        <OnboardingWrapper.Input type="number" value={otp}  placeholder="OTP" onChange={handleChange('otp')} />
                        <OnboardingWrapper.Error>{errors?.otpError}</OnboardingWrapper.Error>
                      </OnboardingWrapper.FormGroup>    
                       <OnboardingWrapper.FormGroup >
                        
                        <OnboardingWrapper.Input type="email"   value={email}  placeholder="Email address" onChange={handleChange('email')} />
                        <OnboardingWrapper.Error>{errors?.emailError}</OnboardingWrapper.Error>
                      </OnboardingWrapper.FormGroup>

                      <OnboardingWrapper.FormGroup >
                        
                        <OnboardingWrapper.Input type="password"  value={password}  placeholder="Password" onChange={handleChange('password')} />
                        <OnboardingWrapper.Error>{errors?.passwordError}</OnboardingWrapper.Error>
                      </OnboardingWrapper.FormGroup>
                     
                      <OnboardingWrapper.FormGroup>
                        <OnboardingWrapper.ResetPass><Link to='/login'>Back to Login</Link></OnboardingWrapper.ResetPass>
                        </OnboardingWrapper.FormGroup>
                
                       <OnboardingWrapper.Button onClick={changePass}>{loading? <small>Loading...</small> : "Change Password"}</OnboardingWrapper.Button>
                       
                            </>
                          )}
                        
                       
                     </OnboardingWrapper.Form>

                 {/* </OnboardingWrapper.Column>

                 <OnboardingWrapper.Column  className='onboarding-bg'> */}
            
            </OnboardingWrapper.Column>
                 
             </OnboardingWrapper.Row>

         </OnboardingWrapper>
      
      <Toaster />
      </>
            
        
    )
}

export default ForgotPassword
