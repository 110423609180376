import React,{useState, useLayoutEffect} from 'react'
import {ContentWrapper, MainWrapper} from '../../component';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import FeatherIcon from 'feather-icons-react'
import DashboardContainer from '../../container/dashboardContainer';
import {Link} from 'react-router-dom'
import axios from 'axios'
import { useHistory } from 'react-router'; 
import { isAuthenticated } from '../../utils/data';
import { OverviewColumn, OverviewContainer,TableContainer,InnerColumn, OverviewHeader, OverviewIcon, OverviewInner, OverviewRow, OverviewTitle, Small } from './user-dashboard-styles/user-dashboard-styles';
import {Loading, Loading2} from '../../container/loading';
import toast, { Toaster } from 'react-hot-toast';
import NumberFormat from 'react-number-format';


const UserDashboard = () => {

const {obj: {name,token}} = isAuthenticated();

const [leaderboard, setLeaderboard] = useState();
const [players, setPlayers] = useState();
const [wallet, setWallet] = useState();
const [loading, setLoading] = useState(false)
const [loadPlayers, setLoadPlayers] = useState(false)
const [transactions, setTransactions] = useState()

const getLeaderboard = async () => {

  setLoading(true)
  try{
  const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/user/api/v1/view-leaderboard`,{
    headers:{
      "Authorization" : ` Bearer ${token}`
    }
   });

   setLoading(false)
   setLeaderboard(data?.obj?.content)

  }catch(error){
          
    if (error instanceof Error) {
      if(error.message === 'Network Error'){
        toast.error('Please check your network connection and try again!');
        setLoading(false)  
       }else{
      
       setLoading(false)
       }
      }
    }
} 

const getTransactionTotal = async () => {

  setLoadPlayers(true)
  try{
  const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/user/api/v1/transaction/total`,{
    headers:{
      "Authorization" : ` Bearer ${token}`
    }
   });

  if(data){
    setLoadPlayers(false)
    setTransactions(data)

    console.log("Trans", data)
 
  }
  }catch(error){
          
    if (error instanceof Error) {
      if(error.message === 'Network Error'){
        toast.error('Please check your network connection and try again!');
        setLoadPlayers(false)
       }else{
      
        setLoadPlayers(false)
       }
      }
    }
} 

const getPlayersCount = async () => {

  setLoadPlayers(true)
  try{
  const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/user/auth/admin/user-count`,{
    headers:{
      "Authorization" : ` Bearer ${token}`
    }
   });

  if(data){
    setLoadPlayers(false)
    setPlayers(data)
 
  }
  }catch(error){
          
    if (error instanceof Error) {
      if(error.message === 'Network Error'){
        toast.error('Please check your network connection and try again!');
        setLoadPlayers(false)
       }else{
      
        setLoadPlayers(false)
       }
      }
    }
} 


const getWallet = async () => {

  setLoadPlayers(true)
  try{
  const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/user/api/v1/wallet/admin/balance`,{
    headers:{
      "Authorization" : ` Bearer ${token}`
    }
   });

  if(data){
    setLoadPlayers(false)
    setWallet(data)
    console.log("wallet", data)
 
  }
  }catch(error){
          
    if (error instanceof Error) {
      if(error.message === 'Network Error'){
        toast.error('Please check your network connection and try again!');
        setLoadPlayers(false)
       }else{
      
        setLoadPlayers(false)
       }
      }
    }
} 



useLayoutEffect(()=>{
  const abortController = new AbortController();
  getLeaderboard();
  getPlayersCount();
  getTransactionTotal();
  getWallet()
  
   return () => {
    abortController.abort()
   }

}, [])

    return (
        <>
          <DashboardContainer>
                <ContentWrapper >
                   
                     <OverviewContainer>
                     <OverviewHeader>Hi {name}!</OverviewHeader>
                          <OverviewRow>
                              
                               <OverviewColumn>
                                 <Link to='/players'>
                               <OverviewInner>
                               <OverviewTitle>
                                     Registrations
                                   </OverviewTitle>
                                   <OverviewIcon>
                                          <FeatherIcon  icon='users' />
                                      </OverviewIcon>
                               </OverviewInner>
                               <OverviewInner>
                                 <div>
                                  <Small>Total</Small>
                                <OverviewTitle size='2rem' weight='600'>
                                   {loadPlayers ? <Loading2 /> : 
                                    <NumberFormat value={players?.Players || '0'} displayType={'text'} thousandSeparator={true}  />
                                   }

                                   </OverviewTitle>
                                  </div>
                                 </OverviewInner>
                                 </Link>
                               </OverviewColumn> 
                               <OverviewColumn>
                                   <Link to='/transactions'>
                                   <OverviewInner>
                                      <OverviewTitle>
                                        Deposit
                                      </OverviewTitle>
                                      <OverviewIcon>
                                          <FeatherIcon  icon='credit-card' />
                                      </OverviewIcon>
                                   </OverviewInner>
                                   <OverviewInner>
                                 <div>
                                  <Small>Total</Small>
                                <OverviewTitle size='2rem' weight='600'>
                               {loadPlayers ? <Loading2 /> : <> 
                               <NumberFormat value={transactions || '0'} displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                                 </>}
                                   </OverviewTitle>
                                  </div>
                                 </OverviewInner>
                                   </Link>
                               </OverviewColumn>
                               <OverviewColumn>
                                   <Link to='/transactions'>
                                   <OverviewInner>
                                      <OverviewTitle >
                                      Game Reserve
                                      </OverviewTitle>
                                      <OverviewIcon>
                                          <FeatherIcon  icon='credit-card' />
                                      </OverviewIcon>
                                   </OverviewInner>
                                   <OverviewInner>
                                 <div>
                                  <Small>Total</Small>
                                <OverviewTitle size='2rem' weight='600'>
                               {loadPlayers ? <Loading2 /> : <> 
                               <NumberFormat value={'0'} displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                                 </>}
                                   </OverviewTitle>
                                  </div>
                                 </OverviewInner>
                                   </Link>
                               </OverviewColumn>
                               {/* <OverviewColumn>
                             
                                 <OverviewInner>
                                 <OverviewTitle>
                                   Wallet
                                   </OverviewTitle>
                                   <OverviewIcon>
                                          <FeatherIcon  icon='credit-card' />
                                      </OverviewIcon>
                                 </OverviewInner>
                                 <OverviewInner>
                                 <div>
                                 {loadPlayers ? <><Small>Wallet types</Small>  <Loading2 /> </> : 
                                      <div className='wallet-type-container'>
                                      <div className='wallet-type-box'>
                                          <div className='wallet-type'>
                                             <FeatherIcon icon='square' size='15px' color='orange' fill='orange'/>Coins</div>
                                         
                                          <NumberFormat value={wallet?.COINS || '0'} displayType={'text'} thousandSeparator={true}  />
                                      </div>
                                      <div className='wallet-type-box'>
                                          <div className='wallet-type'>
                                             <FeatherIcon icon='square' size='15px' color='#d3d3d3' fill='#d3d3d3'/>Points</div>
                                         
                                          <NumberFormat value={wallet?.POINTS || '0'} displayType={'text'} thousandSeparator={true}  />
                                      </div>
                                      <div className='wallet-type-box'>
                                          <div className='wallet-type'>
                                             <FeatherIcon icon='square' size='15px' color='#08e0a3' fill='#08e0a3'/>Token</div>
                                        
                                          <NumberFormat value={wallet?.TOKEN || '0'} displayType={'text'} thousandSeparator={true}  />
                                           
                                      </div>
                                  </div>
                                   }
                                 
                                  </div>
                                 </OverviewInner>
                               
                               </OverviewColumn> */}
                              
                               <OverviewColumn>
                                 <OverviewInner>
                                 <OverviewTitle>
                                     Winnings
                                   </OverviewTitle>
                                   <OverviewIcon>
                                          <FeatherIcon  icon='activity' />
                                      </OverviewIcon>
                                 </OverviewInner>
                                 <OverviewInner>
                                    <div>
                                      <Small>Total</Small>
                                    <OverviewTitle size='2rem' weight='600'>
                                        0
                                      </OverviewTitle>
                                      </div>
                                 
                                 </OverviewInner>
                                    
                               </OverviewColumn>
                               <OverviewColumn>
                                <Link to='/games'>
                                <OverviewInner>
                                 <OverviewTitle>
                                     Games
                                   </OverviewTitle>
                                   <OverviewIcon>
                                          <FeatherIcon  icon='github' />
                                      </OverviewIcon>
                                 </OverviewInner>
                                 <OverviewInner>
                                 <div>
                                  <Small>Total</Small>
                                <OverviewTitle size='2rem' weight='600'>
                                    3
                                   </OverviewTitle>
                                  </div>
                                 </OverviewInner>
                                </Link>

                               
                                   
                               </OverviewColumn>
                          </OverviewRow>
                          <OverviewRow>
                             <OverviewColumn bgColor='transparent'  height='fit-content'>
                               <InnerColumn>
                               <div className='leaderboard'>
                                     {/* <img src={useravatar}  alt='user-avatar' /> */}
                                     <OverviewTitle color='#111' weight='600'>
                                       Game Reserve Accounts
                                      </OverviewTitle>
                                      <div>
                                        <div style={{padding:'1rem 0'}}>
                                        <OverviewTitle color='#111' size='1.1rem'>
                                          Total Game Reserve
                                        </OverviewTitle>

                                          <div style={{background:'#00509d', height:'30px', margin:'.7rem 0'}}>
                                               <div style={{background:'#ed9717',width:'60%', height:'30px'}}>

                                               </div>
                                          </div>
                                          
                                        </div>
                                        <div style={{padding:'1rem 0'}}>
                                        <OverviewTitle color='#111' size='1.1rem'>
                                          Token Reserve
                                        </OverviewTitle>

                                          <div style={{background:'#00509d', height:'30px', margin:'.7rem 0'}}>
                                               <div style={{background:'#ed9717',width:'80%', height:'30px'}}>

                                               </div>
                                          </div>
                                          
                                        </div>
                                        <div style={{padding:'1rem 0'}}>
                                        <OverviewTitle color='#111' size='1.1rem'>
                                          Coin Reserve
                                        </OverviewTitle>

                                          <div style={{background:'#00509d', height:'30px', margin:'.7rem 0'}}>
                                               <div style={{background:'#ed9717',width:'30%', height:'30px'}}>

                                               </div>
                                          </div>
                                          
                                        </div>
                                       
                                      </div>
                                        {/* <div className='view-more'>
                                           <div className='view-more-box'>
                                           <Link to='/'>View More   <FeatherIcon icon='arrow-right' /></Link>
                                           </div>
                                        </div> */}

                                      </div>
                               </InnerColumn> 

                                 <InnerColumn>
                                   <div className='games-report-wrapper'>
                                   <div className='circle'>
                                         <div className='game-title'>Games</div>
                                         <div className='total'>3</div>
                                   </div>
                                      <div className='games-stat'>
                                          <div className='games-stat-box'>
                                              <div className='games-title'> <FeatherIcon icon='square' size='15px' color='orange' fill='orange'/>Avatar</div>
                                              <div className='games-coins'> 20 coins</div>
                                          </div>

                                          <div className='games-stat-box'>
                                              <div className='games-title'> <FeatherIcon icon='square' size='15px' color='red' fill='red'/>Guess Games</div>
                                              <div className='games-coins'> 45 coins</div>
                                          </div>
                                          <div className='games-stat-box'>
                                              <div className='games-title'> <FeatherIcon icon='square' size='15px' color='#0581b6' fill='#0581b6'/>Trivia</div>
                                              <div className='games-coins'> 20 coins</div>
                                          </div>
                                      </div>
                                     
                                   </div>
                                        {/* <div className='view-more'>
                                           <div className='view-more-box'>
                                           <Link to='/'>View More   <FeatherIcon icon='arrow-right' /></Link>
                                           </div>
                                        </div> */}
                                 </InnerColumn>
                              
                             </OverviewColumn>
                             <OverviewColumn bgColor='#fff' height='fit-content'>
                                   <div className='leaderboard'>
                                   {/* <img src={leaderboardpic}   alt='leaderboard' width='60px' /> */}
                                     <OverviewTitle color='#111'>
                                        Leaderboard
                                      </OverviewTitle>
                                     {!loading && (
                                        <TableContainer>
                                        <div className='leaderboard-table'>
                                        <table className='leaders'>
                                          <tbody>
                                          <tr>
                                            <th>Rank</th>
                                            <th>Name</th>
                                          
                                            <th>Points</th>
                                          </tr>
                                          
                                          {leaderboard?.map((item, i)=>(
                                            <tr key={i}>
                                            <td>{i + 1}</td> 
                                            <td>
                                            <div className='user-avatar'>
                                              {/* <img src={avatar} alt='avatar' /> 
                                              
                                              style={{background:`#${Math.floor(Math.random()*16777215).toString(16)}`}}
                                              */}
                                            
                                              <div className='text-avatar' >
                                                {item?.user?.name.charAt(0)}</div>
                                             {item?.user?.name.split(" ")[0]} </div>


                                            </td>
                                           
                                            <td className='points'>{}
                                            <NumberFormat value={item.points} displayType={'text'} thousandSeparator={true}  />
                                             pts
                                             </td>
                                          
                                          </tr>
                                          ))}
                                        
                                          </tbody>
                                        </table>
                                        </div>
                                      </TableContainer>
                                     )}
                                            
                                      </div>
                                       {loading && (
                                          <Loading />
                                       )}
                             </OverviewColumn>
                          </OverviewRow>
                          <Toaster position='top-center' />
                     </OverviewContainer>
                </ContentWrapper>
          </DashboardContainer>
        
      
        </>
    )

}

export default UserDashboard
