import React, {useState, useContext} from 'react'
import logo from '../../assets/images/biggies-logo.svg'
import {OnboardingWrapper} from '../../component';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import {authenticate} from '../../utils/data';
import toast, { Toaster } from 'react-hot-toast';
import { Context } from '../../Context';


const Login = () => {


  const history = useHistory();

  const {state, dispatch} = useContext(Context);

  const [values, setValues] = useState({
    username:'',
    password:'',

    errors:{
      emailError:'',
      passwordError:'',
    }

  });

  const [loading, setLoading] = useState(false);

  const {email, password, errors} = values;

    const handleChange = name => e=>{  

    setValues({...values, errors:{}, [name]: e.target.value})
  }

  
  const loginForm = async(e)=>{

    e.preventDefault();

    setLoading(true);
    console.log('Values', values)
     if(!email){
       setValues({...values, errors:{emailError:"Email is required"}})
       setLoading(false);
     }
   else if(!password){
       setValues({...values, errors:{passwordError: 'Password is required'}})
       setLoading(false);

   }else if(password.length < 8){
       setValues({...values, errors:{passwordError: 'Password must be 8 characters and above!'}})
       setLoading(false);

   }else{

     try{
       
       let payload={   
          email:email,     
          password:password,     
          
         }


         console.log("PAYLOAD", payload)
     
        const {data}  = await axios.post(`${process.env.REACT_APP_API_URL}/user/auth/admin/login`, payload)
         console.log("RESPONSE", data)

          if(data?.status ===1){
              
            toast.error(data?.desc);
             setLoading(false)
          }else{
            setValues({
              email:'',
              password:'',
            });
 
            toast.success("Login successful!");
            dispatch({
             type:'LOGIN',
             payload: data
             })
          
            authenticate(data, ()=>{
              history.push('/dashboard');
            })
          }
  
       }catch(error){
          
        if (error instanceof Error) {
          if(error.message === 'Network Error'){
            toast.error('Please check your network connection!');
            setLoading(false)  
           }else{
            // toast.error(error.response.desc);
           setLoading(false)
           }
    
         }else{
          // toast.error(error.response.desc);
         }
        
       }

    }
    

 }
    return (
      <>
       
         <OnboardingWrapper>
             <OnboardingWrapper.Row>
              
                 <OnboardingWrapper.Column>
                    
                     <OnboardingWrapper.Form>
                         <OnboardingWrapper.ImgWrapper> <img src={logo}  alt='logo' width='120px' /> </OnboardingWrapper.ImgWrapper>
                        <OnboardingWrapper.Title>Login to Admin </OnboardingWrapper.Title>
                       <OnboardingWrapper.FormGroup >
                        
                        <OnboardingWrapper.Input type="email"  placeholder="Email address" onChange={handleChange('email')} />
                        <OnboardingWrapper.Error>{errors?.emailError}</OnboardingWrapper.Error>
                      </OnboardingWrapper.FormGroup>
                     
                      <OnboardingWrapper.FormGroup >
                        
                        <OnboardingWrapper.Input type="password"  placeholder="Your password" onChange={handleChange('password')}  />
                        <OnboardingWrapper.Error>{errors?.passwordError}</OnboardingWrapper.Error>
                      </OnboardingWrapper.FormGroup>
                      <OnboardingWrapper.FormGroup>
                        <OnboardingWrapper.ResetPass><Link to='/forgot-password'>Forgot Password?</Link></OnboardingWrapper.ResetPass>
                        </OnboardingWrapper.FormGroup>
                
                       <OnboardingWrapper.Button type='submit' onClick={loginForm}>{loading? <small>Authenticating...</small> : "Login"}</OnboardingWrapper.Button>
                       
                        
                       
                     </OnboardingWrapper.Form>

                 {/* </OnboardingWrapper.Column>

                 <OnboardingWrapper.Column  className='onboarding-bg'> */}
            
            </OnboardingWrapper.Column>
                 
             </OnboardingWrapper.Row>

         </OnboardingWrapper>
      
      <Toaster />
      </>
            
        
    )
}

export default Login
