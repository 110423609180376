import styled from 'styled-components/macro'



export const Search = styled.input`
background: #EBF5FF;
border: none;   
width: 100%;
max-width: 250px;
height: 40px;
border-radius: 30px;
padding: 0 1rem;
`

export const Container = styled.div`
height: 65px;
display: flex;
align-items: center;
background: #02025d;
justify-content:space-between;
padding: 0 4rem 0 0;
gap:20px;
position: sticky;
top: 0;
z-index: 999;


@media (max-width:600px){
    padding: 0 1rem;
}
.mobile-logo{
    display: none;
    @media (max-width:768px){
        display: flex;
        align-items:center;
        gap: 20px;

        img{
            width: 70px;
        }
    }

}
.topbar-icon{
    display: flex;
    align-items:center;
    gap: 20px;
    @media (max-width:600px){
        gap: 10px;
        svg{
            width:20px;
        }
        img{
            width:30px;
        }
 >${Search}{
     display: none;
 }
}
}


`

