import styled from 'styled-components/macro';

export const Container = styled.div`
display: flex;
justify-content: center;
align-items: center;
/* background: #02025d; */
background: linear-gradient(45deg,#02025d,#020229);

`

export const Row = styled.div`
display: flex;
justify-content: center;
flex-wrap:wrap;
width: 100%;
height: 100vh;
align-items: center;

`

export const Column = styled.div`
flex: 1;
/* img{
    max-height: 650px;
} */


`
export const Error = styled.div`

color: #e70707;
padding-top: 4px;
font-weight: 400;
font-size: 14px;
`

export const Form = styled.form`
margin: 1rem 2rem;
width: 300px;
height: 450px;
margin: auto;
border: 2px solid #fff;
padding: 3rem 2rem;
border-radius: 10px;

.small {
    color: #8a8a8a;
    .terms{
    color: #075eaf;
    text-decoration: none;
    }
}
@media (max-width:600px){
    max-width: 250px;
}
`

export const Title = styled.p`
font-weight: 700;
font-size: 25px;
line-height: 33px;
text-align: center;
color: #fff;

`

export const FormGroup = styled.div`
 display: flex;
flex-direction: column;
margin: 1rem 0;
`

export const Label = styled.label`
padding: .5rem 0;
color:#949494;
`

export const Input = styled.input`
    width: 100%;
    height: 50px;
    border: 1px solid #ebf5ff;
    box-sizing: border-box;
    border-radius: 10px;
    background: #ebf5ff;
    padding: 0 1rem;
    font-size: 14px;
    /* color: #d3d3d3; */

    &:focus-visible{
        outline-width: inherit;
    }

`

export const Button = styled.div`
    background:${({bgColor})=>bgColor ?bgColor :  '#00509d'};
    border-radius: 50px;
    color: #fff;
    width: ${({width})=>width ? width : ' 200px'};
    height: 40px;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    margin: 1rem auto;
    transition: background-color .2s ease-in-out ;

    &:hover{
        background:${({bgColor})=>bgColor ? bgColor :  '#075eaf'};
    }
`

export const ImgWrapper = styled.div`
   display: flex;
   justify-content: center;
    width: 100%;
    
`

export const SmallText = styled.small`
font-size: .9rem;
color: #00509d;
display: flex;
justify-content: center;
gap: 5px;


a{
    color:#FDC500;
    text-decoration: none
}
`
export const ResetPass = styled.small`
font-size: .9rem;
color:#949494;
display:flex;
justify-content: flex-end;


a{
    color: #fff;
    text-decoration: none;
}
`



