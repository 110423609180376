import React, {useState, useEffect, useRef} from 'react'
import { ContentWrapper, CustomModal, OnboardingWrapper, ProgressBar } from '../../component'
import DashboardContainer from '../../container/dashboardContainer';
import axios from 'axios'
import FeatherIcon from 'feather-icons-react'
import {useHistory, Link} from 'react-router-dom'
import toast, {Toaster} from 'react-hot-toast'
import { OverviewColumn, OverviewHeader, OverviewContainer, OverviewInner, OverviewRow, OverviewTitle } from './user-dashboard-styles/user-dashboard-styles';
import { Input, Label, PlayerForm, Select, TableContainer } from './players-styles/players';
import { Button, HeaderWrapper, PlayerColumn, PlayerContainer, PlayerHeader, PlayerSearch } from './players-styles/players'
import test from '../../assets/images/test.png'
import { isAuthenticated } from '../../utils/data';




const EditReward = ({item,showModal, setShowModal, setUpdate}) => {
     
    const fileRef = useRef()

    const [loading, setLoading] = useState(false) 
    const [imageFile, setImageFile] = useState();
    const [image, setImage] = useState(); 
    const [progress, setProgress] = useState(0);
    const [onUploadProgress, setUploadProgress] = useState();
    const [previewImage, setPreviewImage] = useState();
    const {
        obj: { token },
      } = isAuthenticated();

  const [values, setValues] = useState({
         coinValue: '',
         inStock: '',
         name: '',
         pointValue: '', 
         id:'', 
         errors:{
             coinError:'',
             inStockError:'',
             pointError:'',
             nameError:'',
          
         }  
      }
  )


 const [isAvailable, setAvailability] = useState("YES")

const {coinValue, inStock, name, id, pointValue, errors} = values


  
  
const updateReward = async(e)=>{
    e.preventDefault();
    setLoading(true)
    if(!name){
      setValues({...values, errors:{nameError:'Name is required'}})
      setLoading(false)
    }else if(!inStock){
     setValues({...values, errors:{instockError:'Instock is required'}})
     setLoading(false)
    }else if(!pointValue){
     setValues({...values, errors:{pointError:'Point value is required'}})
     setLoading(false)
    }else if(!coinValue){
     setValues({...values, errors:{coinError:'Coin value is required'}});
     setLoading(false)
    }else{
        try{
         let payload ={
             id: id,
            coinValue: coinValue,
            imageUrl: image,
            inStock: inStock,
            isAvailable: isAvailable,
            name: name,
            pointValue: pointValue,    
          }
 

         let {data} =  await axios.put(`${process.env.REACT_APP_API_URL}/games/game-reward`, payload, {
             headers: {
                 "Authorization": ` ${token}`,
               },
         });
         setLoading(false)
         toast.success("Update was successful!");
        
         setTimeout(()=>{
             setShowModal(false);
            
         }, 3000);

         setUpdate(data)
       
         console.log(data)
        }catch(error){
           if(error){
               toast.error(error.response.data.obj.status)
               setLoading(false)
           }else if(error.message === 'Newwork Error'){
            toast.error('Please check your network connection and try again')
           }
        }
    }
   }
 
   useEffect(()=>{
   setValues({...values, coinValue:item.coinValue,
       pointValue:item.pointValue, name: item.name,
       inStock: item.inStock, id:item.id
   });
   setAvailability(item.isAvailable);
   setImage(item.imageUrl)
 
   }, [item]) 

const handleImageUpload = async(e)=>{

        let imageFile = e.target.files[0];

        setImageFile(imageFile)

        setPreviewImage(window.URL.createObjectURL(imageFile) || window.webkitURL.createObjectURL(imageFile));
        // setLoad(true)

        console.log("IMAGE", imageFile)
        if(e.target.files[0].size > 1000000){
   
        // setLoad(false);
        toast.error(`File size should be less than 1MB`);
       
       }else{
        const cloudName = 'doumwledk';
        const imageData = new FormData();
        imageData.append("file", imageFile);
        imageData.append('upload_preset', 'gajtmsrs');
        try{
           
            let {data} =  await axios.post(`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`, imageData,
            {
                
             onUploadProgress: (e)=>{
              setProgress(Math.round((100 * e.loaded) /e.total))
             }
            }
            );
            toast.success('Image upload was successful!');
             setTimeout(()=>{
                setProgress(0) ;
              }, 5000)  
              setImage(data?.url);     
          }catch(err){
            console.log(err)
            // setLoad(false)
            toast.error('Image Upload failed, Try again');
          }
       }
      }

      const uploadFile = ()=>{
        fileRef.current.click();
    }

const handleChange = name=> (e)=>{
    setValues({...values, errors:{} , [name]: e.target.value})
}



    return (
    <>     {showModal && (
        <CustomModal  setShowModal={setShowModal}>
        <PlayerContainer>
         <OverviewHeader>Edit Reward</OverviewHeader>
          <OverviewRow>
              <OverviewColumn bgColor='#fff' height='auto'>
                 <OverviewRow>
                      <OverviewColumn bgColor='#fff' height='auto'>
                      <OverviewInner>
               <PlayerForm padding='0 2rem 0rem 0'>
                 <OnboardingWrapper.FormGroup >
                  <Label>Reward Name</Label>
                  <Input type="text" defaultValue={name} placeholder="Reward Name" onChange={handleChange('name')} />
                  <OnboardingWrapper.Error>{errors?.nameError}</OnboardingWrapper.Error>
                </OnboardingWrapper.FormGroup>
                <OnboardingWrapper.FormGroup > 
                  <Label>Availability</Label>
                  <Select onChange={(e)=>setAvailability(e.target.value)} defaultValue={isAvailable}>
                      <option value="YES">Yes</option>
                      <option value="NO">No</option>
                  </Select>
                 
                </OnboardingWrapper.FormGroup>
                <OnboardingWrapper.FormGroup >
                  <Label>Number Instock</Label>
                  <Input type="number" defaultValue={inStock} placeholder="Number Instock" onChange={handleChange('inStock')} />
                  <OnboardingWrapper.Error>{errors?.instockError}</OnboardingWrapper.Error>
                </OnboardingWrapper.FormGroup>
                 <OverviewRow>
                    <OverviewColumn  bgColor='#fff'>
                    <OnboardingWrapper.FormGroup >
                  <Label>Point Value</Label>
                  <Input type="number" defaultValue={pointValue} placeholder="Points Value" onChange={handleChange('pointValue')} />
                  <OnboardingWrapper.Error>{errors?.pointError}</OnboardingWrapper.Error>
                </OnboardingWrapper.FormGroup>
                    </OverviewColumn>
                    <OverviewColumn  bgColor='#fff'>
                    <OnboardingWrapper.FormGroup >
                  <Label>Coin Value</Label>
                  <Input type="number" defaultValue={coinValue} placeholder="Coins Value" onChange={handleChange('coinValue')} />
                  <OnboardingWrapper.Error>{errors?.coinError}</OnboardingWrapper.Error>
                </OnboardingWrapper.FormGroup>
                    </OverviewColumn>
                 </OverviewRow>
              
                 <Button onClick={updateReward} disabled={loading ? true : false}>{loading ? "Updating..." : "Update"}</Button>
                              
               </PlayerForm>
               </OverviewInner> 
                      </OverviewColumn>
                      <OverviewColumn bgColor='#fff' height='auto'>
                             <div>
                             <div className='reward-image'>
                             {previewImage ?
                             <> <img src={previewImage  || null}  alt=''  /> </>
                             :  <img src={image} alt=''/> }
                                  
                                {!previewImage || !item && (
                                      <h3>Image Here</h3>
                                )}
                              </div>
                              <OnboardingWrapper.FormGroup >
                              <Label>Upload Image</Label>
                             
                              <input type="file" ref={fileRef} onChange={handleImageUpload} hidden  accept='image/*' />
                              <div className='file-input'>
                                  <button className='upload-btn' onClick={uploadFile}>
                                    Upload
                                  </button>
                                  <span>
                                     {imageFile ? imageFile.name : ''}
                                  </span>
                              </div> <br/>
                                   {progress !==0 && (
                                            <ProgressBar  completed={progress}/>
                                     )}
                             
                                 </OnboardingWrapper.FormGroup>
                             </div>
                      </OverviewColumn>
                 </OverviewRow>
              </OverviewColumn>   
          </OverviewRow>
     
        </PlayerContainer>    
        </CustomModal>  
    )}
              
   

             <Toaster position='top-center' />
       </>
    )
}

export default EditReward
