import React, {useState, useEffect} from 'react'
import { ContentWrapper } from '../../component'
import DashboardContainer from '../../container/dashboardContainer';
import axios from 'axios'
import FeatherIcon from 'feather-icons-react' 
import {useHistory, Link} from 'react-router-dom'
import toast, {Toaster} from 'react-hot-toast'
import { OverviewColumn, OverviewHeader, OverviewContainer, OverviewInner, OverviewRow, OverviewTitle } from './user-dashboard-styles/user-dashboard-styles';
import { TableContainer } from './players-styles/players';
import {Loading, Loading2} from '../../container/loading';
import { Button, HeaderWrapper, PlayerColumn, PlayerContainer, PlayerHeader, PlayerSearch } from './players-styles/players';
import moment from 'moment'
import { isAuthenticated } from '../../utils/data';
import { GamesButton } from './games-styles/games-styles';
import EditReward from './edit-reward';


const ManageRewards = () => {
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const [rewards, setRewards] = useState([])
    const [itemDetail, setItem]= useState({});
    const [update, setUpdate] = useState()
    const {obj:{ token}} = isAuthenticated();

    const history = useHistory()

        
            

 const getRewardList = async() =>{
    setLoading(true);
    try{
      const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/games/game-reward/?size=100`, {
        headers:{
            "Authorization" : ` Bearer ${token}`
          }
      });
      setLoading(false);
      setRewards(data?.obj?.gameReward?.sort((a,b) => new Date(a) < new Date(b) ? 1 : -1)) 
    }catch(error){
        setLoading(false);
    }
 }


 const handleDetail = (item)=>{
   setItem(item);
   if(itemDetail){
    setShowModal(true)
   }
 }
 
 useEffect(()=>{
    getRewardList()
 }, [update])
    return (
        <DashboardContainer>  
            <EditReward item={itemDetail} setUpdate={setUpdate} showModal={showModal} setShowModal={setShowModal} />
             <ContentWrapper>
               <PlayerContainer>
               <OverviewContainer>
                  <OverviewHeader>Manage Rewards</OverviewHeader>
                  <GamesButton  onClick={()=>history.push('/add-reward')}>
                     <FeatherIcon icon="plus" size="15px" /> 
                     Add Reward  
                </GamesButton>
                 <TableContainer>
                 
                <br/>

                {loading ? <Loading/> :
                 <table className='reward-table'>
                     <tbody>
                    <tr>
                        <th>Reward Ref</th>
                        <th>Reward Image</th> 
                        <th>Reward Name</th>
                        <th>Item Instock</th>
                        <th>Reward Available</th>
                        <th>Points Value</th>
                        <th>Coins Value</th>
                        <th>Created Date</th>
                      
                        <th>Action</th>
                    </tr>
                    {rewards?.map((reward, i)=>(
                    <tr key={i}>     
                        <td>{i + 1}</td>
                        <td><img src={reward.imageUrl} alt={reward.name} /></td>
                        <td>{reward.name}</td>
                        <td >{reward.inStock}</td>
                        <td style={{color : reward.isAvailable==="YES"?'#008045':'red'}}>
                            {reward.isAvailable}</td>
                        <td>{(reward.pointValue).toLocaleString()} Points</td>
                        <td>{(reward.coinValue).toLocaleString()} Coins</td>
                        <td>{moment(reward.createdby).format('ll')}</td>
                        <td>
                        <div className='cat-action-btn'>
                           <FeatherIcon icon='edit-3' onClick={()=>handleDetail(reward)}/>
                            <FeatherIcon icon='trash-2' />
                        </div>
                        </td>
                    </tr>
                      ))}
                      </tbody>  
                    </table>
                    }
                 </TableContainer>
             </OverviewContainer>
                    
                   
                
                    {/* < ReactPaginate
                        previousLabel={"previous" }
                        nextLabel={ "next" }
                        breakLabel={ "..." }
                        breakClassName={ "break-me" }
                        pageCount={ pageCount }
                        onPageChange={ handlePageClick }
                        containerClassName={ "pagination" }
                        subContainerClassName={ "pages pagination" }
                        activeClassName={ "active" } /> */}
               </PlayerContainer>
             
        </ContentWrapper>

        </DashboardContainer>
    )
}

export default ManageRewards

