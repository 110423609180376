import React, { useState, useEffect } from "react";
import { ContentWrapper, CustomModal } from "../../component";
import {
  ModalColumn,
  ModalHeader,
  ModalRow,
} from "../../component/custom-modal/styles/custom-modal";
import DashboardContainer from "../../container/dashboardContainer";
import { PlayerContainer, Select } from "./players-styles/players";
import { DataSearch } from "./reward-styles/reward-styles";
import {
  FormInput,
  MyFormInputWrapper,
} from "./setting-styles/setting-styles";
import { OverviewHeader } from "./user-dashboard-styles/user-dashboard-styles";
import FeatherIcon from "feather-icons-react";
import {
  GamesButton,
  InputLabel,
  FlexContainer,
} from "./games-styles/games-styles";
import { isAuthenticated } from "../../utils/data";
import { applyFilters } from "../../utils/filter";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { Loading } from "../../container/loading";

const TriviaCreate = () => {
  const [showModal, setshowModal] = useState(false);
  const [showModal2, setshowModal2] = useState(false);
  const [trivials, setTrivias] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [creationStep, setCreationStep] = useState(1);
  const [updateStep, setUpdateStep] = useState(1);
  const [questionText, setquestionText] = useState({
    questionText:''
  });
 
  const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);
  const [option, setOption] = useState({
    isCorrectAnswer: null,
    optionText: null,
    triviaId: null,
  });
  const [ triviaList, setTriviaList] = useState([])
  const [ bulkOptions, setbulkOptions] = useState({
      triviaId: '',
      optionText: '',
      isCorrectAnswer: '',

  })
  const {optionText, isCorrectAnswer} = bulkOptions
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [viewTrivia, setViewTrivia] = useState({});
  const [triviaOptionList, setTriviaOptionList] = useState([]);
  
  const [CreationType , setCreationType] = useState('inputs');
  
  const [uploadingProcess, setProgress] = useState(0);

  const [addMoreOption, setaddMoreOption] = useState(false);
  

  // const [optionsList, setoptionsList ] = useState([])
  const {
    obj: { token },
  } = isAuthenticated();
  // const [page, setPage] = useState(0)
  const [size, setPageSize] = useState(10);
  const [count, setCount] = useState("");
  const openModal = () => {
    setshowModal(true);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(20);

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const transactions = trivials?.slice(indexOfFirstPost, indexOfLastPost);
  const [optionNumbers, setOptionsNumber] = useState(0);

  const numberArray = [...Array(optionNumbers).keys()];

  const PageCount = Math.ceil(count / size);

  const changePage = ({ selected }) => {
    // setPage(selected)
    const filter = {
      page: selected,
    };
    getAlltrivia(filter);
  };
  const formatTime = (time) => {
    return moment(time).calendar();
  };

  const createTrivia = async () => {
    
    const payload = { ...questionText };

    console.log(payload.questionText)
    if(payload.questionText.length == 0){
      toast.error('Please Add your question in the input field')
      return 
    }
    

    setLoading1(true);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/games/trivia-game`,
        payload,
        {
          headers: {
            Authorization: ` ${token}`,
          },
        }
      );

      toast.success("Trivia created successfully");

      setCreationStep(2);
      setLoading1(false);
      getAlltrivia();
      const triviaId = {
        triviaId: data.obj.id,
      };
      setOption({ ...option, ...triviaId });
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === "Network Error") {
          toast.error("Please check your network connection!");
          setLoading1(false);
        } else {
          // toast.error(error.response.desc);
          setLoading1(false);
        }
      } else {
        toast.error(error.response.desc);
        setLoading1(false);
      }
    }
  };
  const createBulkOptions = async () => {
    const payload =  triviaList.map((item)=>{
      var obj = Object.assign({}, item);
      obj["triviaId"] = option.triviaId
      return obj
    })

    setLoading1(true)
    
    try{
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/games/trivia-option/create-bulk`, payload,  {
        headers: {
          Authorization: ` ${token}`,
        },
      })
      console.log(data)
      toast.success("Options added to your question");
      setLoading1(false)
      setTriviaList([])
      setCreationStep(1)
      setshowModal(false)
      getAlltrivia();

    }catch(err){
      console.log(err.response)
      setLoading1(false)
      if(err instanceof Error){
        if(err.message == "Network Error"){
          toast.error('Please check your network connection and try again!')
        }
      }
      if(err.response.status == 401){
        toast.error('Your are not authenticated')
      }else if (err.response.status == 500){
        toast.error('Internal serve error, please try again')
      }
      else {
        toast.error('An error occured while process your request')
      }

      
    }

  }
  const createOption = async (id) => {
    setLoading1(true);

    const { triviaId, optionText, isCorrectAnswer } = option;
    const payload = {
      triviaId: triviaId !== null ? triviaId : id,
      optionText: optionText,
      isCorrectAnswer: isCorrectAnswer,
    };

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/games/trivia-option`,
        payload,
        {
          headers: {
            Authorization: ` ${token}`,
          },
        }
      );

      toast.success("Option added");

      const newOption = data.obj;

      setTriviaOptionList([...triviaOptionList, newOption]);

      setaddMoreOption(false);
      setLoading1(false);
      getAlltrivia();
      

    } catch (error) {
      if (error instanceof Error) {
        if (error.message === "Network Error") {
          toast.error("Please check your network connection!");
          setLoading1(false);
        } else {
          // toast.error(error.response.desc);
          setLoading1(false);
        }
      } else {
        toast.error(error.response.desc);
        setLoading1(false);
      }
    }
  };
  const handleChange = (event) => {
    const { value, name } = event.target;
    setquestionText((questionText) => {
      return {
        ...questionText,
        [name]: value,
      };
    });
  };
  const handleOptionChange = (event) => {
    const { value, name } = event.target;

    setOption({ ...option, [name]: value });
  };

  const handleQuestionUpdate = (event) => {
    const { value, name } = event.target;
    setViewTrivia({ ...viewTrivia, [name]: value });
  };

  const UpdateTrivia = async () => {
    const payload = {
      questionText: viewTrivia.questionText,
      id: viewTrivia.id,
    };

    setLoading1(true);
    try {
      const { data } = await axios.put(
        "https://api.patricia.humbergames.com/games/trivia-game",
        payload,
        {
          headers: {
            Authorization: ` ${token}`,
          },
        }
      );

      toast.success("Trivia udated successfully");

      setLoading1(false);
      getAlltrivia();
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === "Network Error") {
          toast.error("Please check your network connection!");
          setLoading1(false);
        } else {
          // toast.error(error.response.desc);
          setLoading1(false);
        }
      } else {
        toast.error(error.response.desc);
        setLoading1(false);
      }
    }
  };

  const updateOptions = async ({
    triviaId,
    optionText,
    id,
    isCorrectAnswer,
  }) => {
    // const { }
    setLoading1(true);
    const payload = {
      triviaId: triviaId,
      optionText: optionText,
      id: id,
      isCorrectAnswer: isCorrectAnswer,
    };

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/games/trivia-option`,

        payload,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      toast.success("Option updated successfully");

      setLoading1(false);
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === "Network Error") {
          toast.error(
            "Network connect error, Please check your network and try again"
          );
        }
      } else {
        toast.error(error.response.description);
      }
      setLoading1(false);
    }
  };

  const getAlltrivia = async (filters) => {
    setLoading(true);
    const filter = {
      page: filters ? filters.page : 0,
      size: size,
      orderColumn: "id",
      orderDirection: "DESC",
    };
    const URL = applyFilters(
      `${process.env.REACT_APP_API_URL}/games/trivia-game`,
      { ...filter }
    );
    try {
      const { data } = await axios.get(URL, {
        headers: {
          Authorization: ` ${token}`,
        },
      });

      setCount(data?.obj?.count);
      setTrivias(data?.obj?.triviaGame);

      setLoading(false);
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === "Network Error") {
          toast.error("Please check your network connection and try again!");
          setSpinner(false);
        } else {
          toast.error("System Error!");
          setSpinner(false);
        }
      }
      setLoading(false);
    }
  };
  const handleBulk =   (event) =>{
    const {name, value} = event.target
    
    setbulkOptions({...bulkOptions,[name]: value})
    console.log(bulkOptions)
  }
  
  const uploadFile = () => {
    setLoading1(true);
    const formData = new FormData();
    formData.append('file', selectedFile);
     axios.post(
      `${process.env.REACT_APP_API_URL}/games/trivia-game/upload`,
      formData,
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "multipart/form-data",
        },
       
      }
    ).then((res)=>{
      getAlltrivia();
      setLoading1(false);
      setSelectedFile(null)
      toast.success('File uploading success')
      setshowModal(false)
    })
    .catch((err)=>{
      setLoading1(false);
      if(err.response.status == 415){
        toast.error(err.response.data.desc)
      } else if(err.response.status == 400){
        toast.error('No data found')
      }
      else{
        toast.error(err.response.data.desc)
      }
      
    })
  }
  const handleUpload =  (event) => {

    setSelectedFile(event.target.files[0]);
		setIsFilePicked(true);
   
    
    
   
  };
  const addOptions = () =>{
    
      if(creationStep !== 2){
        return false
      }else {
        const mylist = triviaList.concat(bulkOptions)
      setTriviaList(mylist)
      setbulkOptions({})
      // setbulkOptions({
      //   triviaId: '',
      //   optionText: '',
      //   isCorrectAnswer: '',
      // })
      }
      


  }

  //console.log(triviaList)
  useEffect(() => {
    getAlltrivia();
  }, []);
  const checkRightAnswer = (array) => {
    const isCorrectAnswer = array?.find(
      (isCorrect) => isCorrect?.isCorrectAnswer == "YES"
    );
    return isCorrectAnswer?.optionText || "N/A";
  };

  return (
    <DashboardContainer>

      {/* <div>
        <pre>
          <code>
            {JSON.stringify(triviaList)}
          </code>
        </pre>
      </div> */}
      <ContentWrapper>
        <PlayerContainer>
          <OverviewHeader>CREATE TRIVIA</OverviewHeader>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <GamesButton onClick={openModal}>
              <FeatherIcon icon="plus" size="15px" />
              Add Question
            </GamesButton>
            <GamesButton>Total: {count?  count :'0'}</GamesButton>
          </div>

          <div className="players-table">
            {loading && <Loading />}
            {!loading && (
              <table className="players">
                <tbody>
                  <tr>
                    <th>ID</th>
                    <th>QUESTIONS</th>
                    <th>OPTION</th>
                    <th>CORRECT ANSWER</th>
                    <th>DATE CREATED</th>
                    <th>ACTIONS</th>
                  </tr>

                  {trivials?.map((trivia, i) => (
                    <tr key={i + "trivia"}>
                      <td>{i + 1}</td>
                      <td className="points">{trivia?.questionText}</td>
                      <td className="points">
                        {trivia?.triviaOptionList?.length}{" "}
                      </td>
                      <td className="points">
                        {checkRightAnswer(trivia?.triviaOptionList)}
                      </td>
                      <td>{formatTime(trivia?.createdTime)}</td>
                      <td className='points'>
                                      <div className='cat-action-btn'>
                                        <FeatherIcon icon='eye' onClick={()=>{setViewTrivia(trivia);
                              setshowModal2(true);
                              setTriviaOptionList(trivia?.triviaOptionList)}}/>
                                        <FeatherIcon icon='trash-2' />
                                      </div>
                                    </td>
                     
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>

          {showModal && (
            <CustomModal width="600px" setShowModal={setshowModal}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  boxSizing: "border-box",
                  padding: "10px 20px",
                  maxHeight: '500px',
                  overflowY: 'auto'
                }}
              >
                <h2 style={{ padding: "0 0 20px 0" }}>
                  Create Trivia Questions
                </h2>
                <FlexContainer justifyContent={"flex-start"}>
                  <InputLabel onClick={()=>setCreationType('upload')}
                    width={"220px"}
                    bgColor={"#FF932C"}
                    
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      {" "}
                      Upload File
                    </div>
                  </InputLabel>
                  <span> OR </span>
                  <InputLabel onClick={()=>setCreationType('inputs')}
                    width={"220px"}
                    bgColor={"#FF932C"}
                   
                    bgColor={"none"}
                    color={"black"}
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      Create Game
                    </div>
                  </InputLabel>
                </FlexContainer>

                {CreationType == "inputs" && (
                  <>
                    <div style={{ display: "flex", width: "100%" }}>
                      <FormInput
                        name="questionText"
                        onChange={handleChange}
                        width="90%"
                        placeholder="Enter Trivia question"
                      />
                    </div>
                    {
                      triviaList?.length > 0 && (
                        <>
                          {
                            triviaList.map((list, i)=>(
                              <div key={i + 'option'}
                        style={{
                          width: "100%",
                          margin: "20px 0",
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        <FormInput 
                          style={{
                            border: "none",
                            borderBottom: "1px solid",
                            borderRadius: "0",
                            width: "269px",
                          }}
                          value={list?.optionText}
                          onChange={(e)=>{
                            triviaList[i].optionText = e.target.value;
                              setTriviaList([...triviaList]);
                                
                          }}
                          width="90%"
                          placeholder="Enter Options"
                          name="optionText"
                        />
                        <Select value={list?.isCorrectAnswer} name="isCorrectAnswer" onChange={(e)=>{
                                  triviaList[i].isCorrectAnswer = e.target.value;
                                  setTriviaList([...triviaList]);
                        }} width="170px" height="35px">
                          <option selected value="">
                            Select Correct
                          </option>
                          <option value="YES">YES</option>
                          <option value="NO"> NO</option>
                        </Select>
                      </div>
                            ))
                          }
                        </>
                      )
                    }
                  
                      <div
                        style={{
                          width: "100%",
                          margin: "20px 0",
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        <FormInput 
                          style={{
                            border: "none",
                            borderBottom: "1px solid",
                            borderRadius: "0",
                            width: "269px",
                          }}
                          onChange={handleBulk}
                          width="90%"
                          placeholder="Enter Options"
                          name="optionText"
                          disabled={creationStep !== 2}
                        />
                        <Select name="isCorrectAnswer" onChange={handleBulk} width="170px" height="35px">
                          <option selected value="">
                            Select Correct
                          </option>
                          <option value="YES">YES</option>
                          <option value="NO"> NO</option>
                        </Select>
                      </div>
                  
                    <a disabled={creationStep !== 2}
                      onClick={addOptions}
                      type="button"
                      style={{ color: "#2B9FDD",marginTop:'10px', alignItems: "center",width: '134px' }}
                      className="d-flex "
                    >
                      {" "}
                      <FeatherIcon icon="plus" />
                      Add options
                    </a>

                    <div>
                      
                      {
                        creationStep == 1 && (
                          <GamesButton   onClick={createTrivia}>  {loading1 ? 'Saving....' :'Save question'} </GamesButton>
                        )
                      }
                      {
                        creationStep == 2 && (
                          <GamesButton  onClick={createBulkOptions}> {loading1 ? 'Saving...' : 'Save options'} </GamesButton>
                        )
                      }
                    </div>
                  </>
                )}

                {
                  CreationType == "upload" && (
                    
                  
                    <>
                    <FlexContainer  justifyContent={"center"}>
                  <InputLabel radius="20px" onClick={()=>setCreationType('upload')}
                    width={"220px"}
                    htmlFor="upload"
                  >
                  <span className="d-flex" style={{justifyContent: 'center'}}>{selectedFile?.name ? selectedFile?.name :'Select file'  }</span>
                    <input onChange={handleUpload}  id="upload" style={{display: "none"}}  type="file"/>
                  </InputLabel>
                  
                  
                  <GamesButton style={{margin: "0"}} onClick={uploadFile}>
                    {loading1 ? 'Uploading' : 'Upload'}
            </GamesButton>
                </FlexContainer>

                    </>
                  )
                }
              </div>
            </CustomModal>
          )}

         
          {showModal2 && (
            <CustomModal width="600px" setShowModal={setshowModal2}>
              <div style={{ width: "100%" }}>
                <h3>
                  UPDATE TRIVIA GAME {loading1 && <small>updating.....</small>}
                </h3>

                {updateStep == 1 && (
                  <>
                    <div style={{ margin: "20px 0" }}>
                      <FormInput
                        name="questionText"
                        onChange={handleQuestionUpdate}
                        width="80%"
                        value={viewTrivia.questionText}
                        placeholder="How many housemates were in the Big Brother house in week 1?"
                      />
                    </div>
                    <div className="d-flex" style={{ gap: "20px" }}>
                      <GamesButton onClick={UpdateTrivia}>
                        {" "}
                        {loading1 ? "Saving" : "Save"}
                      </GamesButton>
                      <GamesButton
                        onClick={() => {
                          setUpdateStep(2);
                        }}
                      >
                        {" "}
                        View Options
                      </GamesButton>
                    </div>
                  </>
                )}

                {updateStep == 2 && (
                  <>
                    {triviaOptionList.map((options, i) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            width: "80%",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <MyFormInputWrapper>
                            <label htmlFor="optiona">Option {i + 1} </label>
                            <FormInput
                              width="120px"
                              id="optiona"
                              value={options.optionText}
                              placeholder="options"
                              name="optionText"
                              onChange={(e) => {
                                triviaOptionList[i].optionText = e.target.value;
                                setTriviaOptionList([...triviaOptionList]);
                              }}
                            />
                          </MyFormInputWrapper>

                          <Select
                            value={options.isCorrectAnswer}
                            name="isCorrectAnswer"
                            width="20%"
                            height="35px"
                            onChange={(e) => {
                              triviaOptionList[i].isCorrectAnswer =
                                e.target.value;
                              setTriviaOptionList([...triviaOptionList]);
                            }}
                          >
                            <option selected disabled>
                              Choose..
                            </option>
                            <option value="YES">YES</option>
                            <option value="NO">NO</option>
                          </Select>
                          <GamesButton width={'none'}
                            style={{ marginTop: 0 }}
                            onClick={() => {
                              updateOptions(options);
                            }}
                          >
                            {" "}
                            Save
                          </GamesButton>
                        </div>
                      );
                    })}

                    {addMoreOption && (
                      <div
                        style={{
                          display: "flex",
                          width: "80%",
                          flexWrap: "wrap",
                          justifyContent: "space-between",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <MyFormInputWrapper>
                          <label htmlFor="optiona">Option </label>
                          <FormInput
                            width="120px"
                            id="optiona"
                            placeholder="options"
                            name="optionText"
                            onChange={handleOptionChange}
                          />
                        </MyFormInputWrapper>

                        <Select
                          name="isCorrectAnswer"
                          width="20%"
                          height="35px"
                          onChange={handleOptionChange}
                        >
                          <option selected disabled>
                            Choose..
                          </option>
                          <option value="YES">YES</option>
                          <option value="NO">NO</option>
                        </Select>
                        <GamesButton
                          style={{ marginTop: 0 }}
                          onClick={() => {
                            createOption(viewTrivia?.id);
                          }}
                        >
                          {" "}
                          Save new
                        </GamesButton>
                      </div>
                    )}

                    <div className="d-flex" style={{ gap: "20px" }}>
                      <GamesButton
                        onClick={() => {
                          setUpdateStep(1);
                        }}
                      >
                        {" "}
                        Back
                      </GamesButton>

                      <GamesButton
                        onClick={() => {
                          setaddMoreOption(true);
                        }}
                      >
                        <FeatherIcon icon="plus" size="15px" />
                        Add Option
                      </GamesButton>
                    </div>
                  </>
                )}
              </div>
            </CustomModal>
          )}
          {/* {true && (
            <CustomModal width="400px" setShowModal={setshowModal2}>
              <div style={{ width: "100%" }}>
                <h3>UPLOAD FILE</h3>
              </div>
              <div
                className="d-flex"
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <InputLabel htmlFor="upload">
                  <div className="d-flex justify-content-center align-items-center"><FeatherIcon icon="upload" color="white" /></div>
                  <progress id="file" value={uploadingProcess} max="100"> 32% </progress>
                  <input accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"  onChange={handleUpload} type="file" id="upload" style={{ display: "none" }} />
                </InputLabel>
               
              </div>
            </CustomModal>
          )} */}
          {/* <CustomModal width="600px">
                        <div style={{width: '80%'}}>
                            <h1>TRIVIA GAME</h1>
                            <MyFormInput placeholder="How many housemates were in the Big Brother house in week 1?" />
                            <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', columnGap: '30px'}}>
                                <div style={{width: '40%'}}><label htmlFor="optiona">Option A</label><MyFormInput id="optiona" placeholder="options" /></div>
                                <div style={{width: '40%'}}><label htmlFor="optiona">Option B</label><MyFormInput id="optiona" placeholder="options" /></div>
                                <div style={{width: '40%'}}><label htmlFor="optiona">Option C</label><MyFormInput id="optiona" placeholder="options" /></div>
                                <div style={{width: '40%'}}><label htmlFor="optiona">Option D</label><MyFormInput id="optiona" placeholder="options" /></div>
                                <div style={{width: '40%'}}>
                                    <label htmlFor="correctanswer">Select Correct Answer</label>
                                    <select>
                                        <option>Pere</option>
                                        <option>Bambam</option>
                                        <option>Liquorise</option>
                                        <option>Sasha</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </CustomModal> */}

          <div style={{ justifyContent: "center", display: "flex" }}>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={PageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        </PlayerContainer>
      </ContentWrapper>
      <Toaster />
    </DashboardContainer>
  );
};

export default TriviaCreate;
