import styled from 'styled-components/macro'


export const OverviewContainer = styled.div`
display: flex;
flex-direction: column;
width: 100%;
/* padding-top: 2rem; */
gap: 30px;


@media (max-width:600px){
    padding-top: 0rem;
}


`

export const OverviewHeader = styled.div`
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1.5rem;

    @media (max-width:600px){
        margin-bottom: 0rem;
    }
   
`

export const OverviewRow = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap:wrap;
  justify-content: center;

`

export const OverviewColumn = styled.div`
     display: ${({display})=>display};
     align-items: ${({alignItem})=>alignItem};
     justify-content: ${({centerContents})=>centerContents};
     flex: ${({flexWidth})=>flexWidth ? flexWidth : '1 1 17%'};
     background: ${({bgColor})=>bgColor ? bgColor : '#00509d'}; 

    height: ${({height})=>height ? height : '  130px'}; 
    border-radius: 7px; 
     
    &:nth-child(3){
        flex: 1 1 23%;

        @media (max-width:600px){
            flex: 1 1 100%;
        }
    }
    
    th{
        color: #000;
    }
    .view-more-box{
        display: flex;
       justify-content: flex-end;
    }
       .view-more a{
    display: flex;
    align-items: center;
    gap: 10px;
    background: #031c77;
    height: 35px;
    padding: 0 .5rem;
    color: #fff;
    margin-top:1rem;
    width: 120px;
    border-radius: 8px;
   
    }
    .leaderboard{
        padding: 1rem;
      
    
     
    }

   .circle{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 6rem;
    height: 6rem;
    border-top: 8px solid #02025d;
    border-right: 8px solid #ed9717;
    border-bottom: 8px solid #02025d;
    border-left: 8px solid #02025d;
    border-radius: 50%;
    .game-title{
      font-size: .8rem;
    }
    .total{
        font-size: 3rem;
        font-weight: 600;
        color: #02025d;
      }
   }

`

export const OverviewInner = styled.div`  
    font-size: 1.5rem;
    padding: .5rem ;
    display: flex;
    justify-content: space-between;
    align-items:center;

    .wallet-type-container {
        display: flex;
        flex-direction: column;
        gap: 3px;
    .wallet-type-box{
    display: flex;
    font-size: 1rem;
    justify-content: space-between;
    gap: 30px;
    color: #fff;
    .wallet-type{
        display:flex;
        align-items:center;
        gap:5px;
      }
    }
}
   
`

export const OverviewTitle = styled.div`
    font-size: ${({size})=>size? size : '1.3rem'};
    font-weight:${({weight})=>weight? weight : '400'};
    color: ${({color})=>color ? '#111' : '#fff'}; 
    
    span{
        font-family:Arial, Helvetica, sans-serif;
    }
    
   @media (max-width:600px){
    font-size: ${({size})=>size? '1.5rem' : '1rem'};
   } 
   
`
export const OverviewIcon= styled.div`
    display: flex;
    background: #fff;
    color: #111;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 50%; 
    
   
`

export const Small = styled.div`
font-size:.8rem;
color:#fff;

`

export const TableContainer = styled.div`
margin-top:1.3rem;
.leaderboard-table{
    /* overflow-x: scroll; */
}

.points{
color: #075eaf;
font-weight: 600;
}
.user-avatar{
    display: flex;
    align-items: center;
    gap: 10px;
}

.leaders, tbody td, th{
    
    /* max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; */
  }



`

export const InnerColumn = styled.div`

background: #fff;
margin-bottom: 2rem;
padding: 1rem;
border-radius: 10px;

.games-report-wrapper{
    display: flex;
    gap: 40px;
    padding: .9rem;
    flex-wrap: wrap;
    .games-stat{
        display:flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;

       .games-stat-box{
        display: flex;
       align-items: center;
       gap: 10px;

       .games-title{
        display: flex;
       align-items: center;
       gap: 10px;
       width: 170px;
       color: #a7a5a5;
       }
       .games-coins{
        color: #a7a5a5;
       }
       } 
    }
}
`