import React, {useState, useEffect} from 'react'
import { ContentWrapper, OnboardingWrapper } from '../../component'
import DashboardContainer from '../../container/dashboardContainer'
import FeatherIcon from 'feather-icons-react'
import { Button, HeaderWrapper, Input,Label, PlayerForm,Select, PlayerContainer, PlayerHeader } from './players-styles/players'
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { isAuthenticated } from '../../utils/data'
import { Small } from './user-dashboard-styles/user-dashboard-styles'
import { FormGroup, SettingsContainer, SettingsRow, Subtitle } from './setting-styles/setting-styles'

const Settings = () => {
  
    const history = useHistory()

    const addPlayer = ()=>{
        history.push('/players')
    }

  const {obj: {token}} = isAuthenticated()
 
    const [values, setValues] = useState({
      username:'',
        amount:'',
        tokenValue:'',
   
       
    
        errors:{
          usernameError:'',
          emailError:'',
          nameError:'',
          phoneError:'',
          roleError:'',
        
        }
    
      });
    

const avatarRoles = [
  {
    role:"Parimutuel",
    value:"Parimuel",
  },
  {
    role:"Fixed",
    value:"Fixed",
  },

]

const guessRoles = [
  {
    role:"Parimutuel",
    value:"Parimuel",
  },
  {
    role:"Fixed",
    value:"Fixed",
  },

]

      const [loading, setLoading] = useState(false);
    
      const {amount, username, role,  errors} = values;
      const [searchTerm, setSearchTerm] = useState(''); 
    
        const handleChange = name => e=>{  
    
        setValues({...values, errors:{}, [name]: e.target.value})
      }
    



   
      
    //   const addPlayerForm = async(e)=>{
    
    //     e.preventDefault();
    
    //     setLoading(true);
    //     console.log('Values', values)
    //      if(!amount){
    //        setValues({...values, errors:{usernameError:"Username is required"}})
    //        setLoading(false);
    //      }
    //    else if(!username){
    //        setValues({...values, errors:{emailError: 'Email is required'}})
    //        setLoading(false);

    
    //    }else if(!username){
    //        setValues({...values, errors:{nameError: 'Name is required'}})
    //        setLoading(false);
    
    //    }
    //   else if(!phone){
    //     setValues({...values, errors:{phonedError: 'Phone is required'}})
    //     setLoading(false);
 
    //   }
    //   // else if(!role){
    //   //   setValues({...values, errors:{roleError: 'Role is required'}})
    //   //   setLoading(false);
 
    //   // }
    //    else{
    
    //      try{
           
    //        let payload={   
    //         username:username,
    //         email: email,
    //         name: username,
    //         phone:phone,
    //         role: role,    
              
    //          }
    
    
    //          console.log("PAYLOAD", payload)
         
    //         const {data}  = await axios.post(`https://api.patricia.humbergames.com/user/auth/admin/create-user`, payload,{
    //           headers:{
    //             "Authorization" : `Bearer ${token}`
    //           }
    //         })
    //          console.log("RESPONSE", data)
    
    //           if(data?.status ===1){
                  
    //             toast.error(data?.desc);
    //              setLoading(false)
    //           }else{
    //             if(data?.status ===0){
    //               toast.success(`Created successful!`);
               
    //             setLoading(false)
    //             }
                
    //           }
      
    //        }catch(error){
              
    //         if (error instanceof Error) {
    //           if(error.message === 'Network Error'){
    //             toast.error('Please check your network connection!');
    //             setLoading(false)  
    //            }else{
               
    //            setLoading(false)
    //            }
        
    //          }else{
          
    //          }
            
    //        }
    
    //     }
        
    
    //  }

    return (
        <DashboardContainer>
        <ContentWrapper>
               <PlayerContainer>
                    <HeaderWrapper>
                    <PlayerHeader>
                        Game Settings
                    </PlayerHeader>
                    {/* <Button onClick={addPlayer}><FeatherIcon icon='plus' /> View Users</Button> */}
                    </HeaderWrapper>
                    <div className='player-form'>
                      <SettingsContainer>  
                       
                        <div>
                        <h3>Game Currency</h3>
                        <small>Naira conversion  to Token</small>
                        <SettingsRow>
                        <FormGroup >
                            <Label>Naira</Label>
                            <Input type="number" value={amount}  placeholder="N100" onChange={handleChange('amount')} />
                            <OnboardingWrapper.Error>{errors?.usernameError}</OnboardingWrapper.Error>
                        </FormGroup> 
                        
                        <FormGroup >
                            <Label>Token</Label>
                            <Input type="number" value={username}  placeholder="1 Token" onChange={handleChange('token')} />
                            <OnboardingWrapper.Error>{errors?.usernameError}</OnboardingWrapper.Error>
                        </FormGroup>  
                        </SettingsRow>
                        </div>


                        <div>
                         <h3>Game Entry</h3>
                         <small>Input the cost to enter a game.</small>
                         
                        <SettingsRow>
                        <FormGroup >
                        <Label>Trivia</Label>
                            <Input type="number" value={username}  placeholder="Trivia (02 Tokens)" onChange={handleChange('username')} />
                            <OnboardingWrapper.Error>{errors?.usernameError}</OnboardingWrapper.Error>
                        </FormGroup> 
                        <FormGroup >
                        <Label>Guess </Label>
                            <Input type="number" value={username}  placeholder="Guess (10 Tokens)" onChange={handleChange('username')} />
                            <OnboardingWrapper.Error>{errors?.usernameError}</OnboardingWrapper.Error>
                        </FormGroup> 
                        <FormGroup >
                        <Label>Avatar</Label>
                            <Input type="number" value={username}  placeholder="Avatar (10 Tokens)" onChange={handleChange('username')} />
                            <OnboardingWrapper.Error>{errors?.usernameError}</OnboardingWrapper.Error>
                        </FormGroup>  
                        </SettingsRow>
                        </div>

                        
                        <div>
                         <h3>Game Scoring</h3>
                         <small>Determine what a player is rewarded with, for activities on the platform.</small>

                         <h4>*POINTS</h4>
                         <small>Top-Up (Purchase Tokens)</small>
                        <SettingsRow>
                        <FormGroup >
                        <Label>Web</Label>
                            <Input type="number" value={username}  placeholder="1000points" onChange={handleChange('username')} />
                            <OnboardingWrapper.Error>{errors?.usernameError}</OnboardingWrapper.Error>
                        </FormGroup> 
                        <FormGroup >
                        <Label>Mobile </Label>
                            <Input type="number" value={username}  placeholder="1000points" onChange={handleChange('username')} />
                            <OnboardingWrapper.Error>{errors?.usernameError}</OnboardingWrapper.Error>
                        </FormGroup> 
                        

                        </SettingsRow>

                        <Subtitle>Play Game</Subtitle>
                        <SettingsRow>
                        <FormGroup >
                        <Label>Trivia</Label>
                            <Input type="number" value={username}  placeholder="500points" onChange={handleChange('username')} />
                            <OnboardingWrapper.Error>{errors?.usernameError}</OnboardingWrapper.Error>
                        </FormGroup> 
                        <FormGroup >
                        <Label>Guess </Label>
                            <Input type="number" value={username}  placeholder="500points" onChange={handleChange('username')} />
                            <OnboardingWrapper.Error>{errors?.usernameError}</OnboardingWrapper.Error>
                        </FormGroup> 
                        <FormGroup >
                        <Label>Avatar </Label>
                            <Input type="number" value={username}  placeholder="500points" onChange={handleChange('username')} />
                            <OnboardingWrapper.Error>{errors?.usernameError}</OnboardingWrapper.Error>
                        </FormGroup> 
                        
                        

                        </SettingsRow>

                        <h4>*COINS</h4>
                        <SettingsRow>
                        <FormGroup >
                        <Label>Win a Trivia Play</Label>
                            <Input type="number" value={username}  placeholder="1 coin" onChange={handleChange('username')} />
                            <OnboardingWrapper.Error>{errors?.usernameError}</OnboardingWrapper.Error>
                        </FormGroup> 
                        <FormGroup >
                        <Label>Win a Guess Play </Label>
                        <Select value={role}  onChange={handleChange('role')}>
                           {guessRoles.map((role)=>(
                             <option value={role.value}>{role.role}</option>
                           ))}
                            
                         </Select>
                            <OnboardingWrapper.Error>{errors?.usernameError}</OnboardingWrapper.Error>
                        </FormGroup> 
                       
                        <FormGroup >
                        <Label>Win an Avatar Play </Label>
                        <Select value={role}  onChange={handleChange('role')}>
                           {avatarRoles.map((role)=>(
                             <option value={role.value}>{role.role}</option>
                           ))}
                            
                         </Select>
                            <OnboardingWrapper.Error>{errors?.usernameError}</OnboardingWrapper.Error>
                        </FormGroup> 
                        
                        

                        </SettingsRow>
                        </div>




                       <br/><br/>
                     
                       <OnboardingWrapper.Button >{loading? <small>Loading...</small> : "Save "}</OnboardingWrapper.Button>
                       
                        
                     </SettingsContainer>
                                        
                    </div>
               </PlayerContainer>
        </ContentWrapper>
        <Toaster position='top-center'/>
    </DashboardContainer>
    )
}

export default Settings
