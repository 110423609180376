import React, {useState, useEffect, useRef} from 'react'
import { ContentWrapper, OnboardingWrapper, ProgressBar } from '../../component'
import DashboardContainer from '../../container/dashboardContainer';
import axios from 'axios'
import FeatherIcon from 'feather-icons-react'
import {useHistory, Link} from 'react-router-dom'
import toast, {Toaster} from 'react-hot-toast'
import { OverviewColumn, OverviewHeader, OverviewContainer, OverviewInner, OverviewRow, OverviewTitle } from './user-dashboard-styles/user-dashboard-styles';
import { Input, Label, PlayerForm, Select, TableContainer } from './players-styles/players';
import { Button, HeaderWrapper, PlayerColumn, PlayerContainer, PlayerHeader, PlayerSearch } from './players-styles/players'
import test from '../../assets/images/test.png'
import { isAuthenticated } from '../../utils/data';




const AddReward = () => {
     
    const fileRef = useRef()

    const [loading, setLoading] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(false)
    const [imageFile, setImageFile] = useState();
    const [image, setImage] = useState();
    const [onUploadProgress, setUploadProgress] = useState();
    const [previewImage, setPreviewImage] = useState();
    const [progress, setProgress] = useState(0);
    const {
        obj: { token },
      } = isAuthenticated();

  const [values, setValues] = useState({
         coinValue: '',
         imageUrl: "",
         inStock: "",
         name: "",
         pointValue: "",  
         errors:{
             coinError:'',
             inStockError:'',
             pointError:'',
             nameError:'',
          
         }  
      }
  )
 const [isAvailable, setAvailability] = useState("YES")

  const {coinValue, imageUrl, inStock, name, pointValue, errors} = values


  const createReward = async(e)=>{
   e.preventDefault();
   setLoading(true)
   if(!name){
     setValues({...values, errors:{nameError:'Name is required'}})
     setLoading(false)
   }else if(!inStock){
    setValues({...values, errors:{instockError:'Instock is required'}})
    setLoading(false)
   }else if(!pointValue){
    setValues({...values, errors:{pointError:'Point value is required'}})
    setLoading(false)
   }else if(!coinValue){
    setValues({...values, errors:{coinError:'Coin value is required'}})
    setLoading(false)

   }else{
       try{
        let payload ={
            coinValue: parseInt(coinValue),
            imageUrl: image,
            inStock: parseInt(inStock),
            isAvailable: isAvailable,
            name: name,
            pointValue: parseInt(pointValue),    
         }

    console.log(payload)
        let {data} =  await axios.post(`${process.env.REACT_APP_API_URL}/games/game-reward`, payload, {
            headers: {
                "Authorization": ` ${token}`,
              },
        });
         
        toast.success('Reward created successful!');
        setValues({...values, coinValue:'',
          pointValue:'', name: '',
          inStock: '', id:''
      });
      setAvailability(''); 
      setImage(null)
        setLoading(false);
       }catch(error){
          toast.error(error.message);
          setLoading(false)
       }
   }
  }

const handleImageUpload = async(e)=>{

        let imageFile = e.target.files[0];

        setImageFile(imageFile)

        setPreviewImage(window.URL.createObjectURL(imageFile) || window.webkitURL.createObjectURL(imageFile));
        setLoadingProgress(true)

        console.log("IMAGE", imageFile)
        if(e.target.files[0].size > 1000000){
   
          setLoadingProgress(false);
        toast.error(`File size should be less than 1MB`);
       
       }else{
        const cloudName = 'doumwledk';
        const imageData = new FormData();
        imageData.append("file", imageFile);
        imageData.append('upload_preset', 'gajtmsrs');
        try{
           
            let {data} =  await axios.post(`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`, imageData,
            {           
            onUploadProgress: (e)=>{      
                let percent = Math.floor((100 * e.loaded) /e.total)
                if(percent <= 100){
                    setProgress(percent)
                }
               }
               }
            );
            toast.success('Image upload was successful!');
              
              setImage(data?.url);  
              setTimeout(()=>{
                setProgress(0) ;
              }, 5000)   
          }catch(err){
            console.log(err)
            setLoadingProgress(false)
            toast.error('Image Upload failed, Try again');
          }
       }
      }

      const uploadFile = ()=>{
        fileRef.current.click();
    }

const handleChange = name=> (e)=>{
    setValues({...values, errors:{} , [name]: e.target.value})
}

    return (
        <DashboardContainer>  
             <ContentWrapper>
               <PlayerContainer>
                <OverviewHeader>Create Reward</OverviewHeader>
                 <OverviewRow>
                     <OverviewColumn bgColor='#fff' height='auto'>
                        <OverviewRow>
                             <OverviewColumn bgColor='#fff' height='auto'>
                             <OverviewInner>
                      <PlayerForm>
                        <OnboardingWrapper.FormGroup >
                         <Label>Reward Name</Label>
                         <Input type="text" value={name} placeholder="Reward Name" onChange={handleChange('name')} />
                         <OnboardingWrapper.Error>{errors?.nameError}</OnboardingWrapper.Error>
                       </OnboardingWrapper.FormGroup>
                       <OnboardingWrapper.FormGroup >
                         <Label>Availability</Label>
                         <Select onChange={(e)=>setAvailability(e.target.value)} value={isAvailable}>
                             <option value="YES">Yes</option>
                             <option value="NO">No</option>
                         </Select>
                        
                       </OnboardingWrapper.FormGroup>
                       <OnboardingWrapper.FormGroup >
                         <Label>Number Instock</Label>
                         <Input type="number" value={inStock} placeholder="Number Instock" onChange={handleChange('inStock')} />
                         <OnboardingWrapper.Error>{errors?.instockError}</OnboardingWrapper.Error>
                       </OnboardingWrapper.FormGroup>
                        <OverviewRow>
                           <OverviewColumn  bgColor='#fff'>
                           <OnboardingWrapper.FormGroup >
                         <Label>Point Value</Label>
                         <Input type="number" value={pointValue} placeholder="Points Value" onChange={handleChange('pointValue')} />
                         <OnboardingWrapper.Error>{errors?.pointError}</OnboardingWrapper.Error>
                       </OnboardingWrapper.FormGroup>
                           </OverviewColumn>
                           <OverviewColumn  bgColor='#fff'>
                           <OnboardingWrapper.FormGroup >
                         <Label>Coin Value</Label>
                         <Input type="number" value={coinValue} placeholder="Coins Value" onChange={handleChange('coinValue')} />
                         <OnboardingWrapper.Error>{errors?.coinError}</OnboardingWrapper.Error>
                       </OnboardingWrapper.FormGroup>
                           </OverviewColumn>
                        </OverviewRow>
                     
                        <Button onClick={createReward}  disabled={loading ? true : false}>{loading ? "Loading..." : "Publish"}</Button>
                                     
                      </PlayerForm>
                      </OverviewInner>
                             </OverviewColumn>
                             <OverviewColumn bgColor='#fff' height='auto'>
                                    <div style={{width:'50%'}}>
                                    <div className='reward-image'>
                                    {previewImage && (
                                    <> <img src={previewImage  || null}   /> </>
                                )}
                                         {/* <img src={test} alt=''/> */}
                                       {!previewImage && (
                                             <h3>Image Here</h3>
                                       )}
                                     </div>
                                     <OnboardingWrapper.FormGroup >
                                     <Label>Upload Image</Label>
                                    
                                     <input type="file" ref={fileRef} onChange={handleImageUpload} hidden  accept='image/*' />
                                     <div className='file-input'>
                                         <button className='upload-btn' onClick={uploadFile}>
                                           Upload
                                         </button>
                                         <span>
                                            {imageFile ? imageFile.name : ''}
                                         </span>
                                     </div>
                                        <br />
                                      {progress !==0 && (
                                            <ProgressBar  completed={progress}/>
                                     )}
                                        </OnboardingWrapper.FormGroup>
                                    </div>
                             </OverviewColumn>
                        </OverviewRow>
                     </OverviewColumn>   
                 </OverviewRow>
            
               </PlayerContainer>        
        </ContentWrapper>

             <Toaster position='top-center' />
        </DashboardContainer>
    )
}

export default AddReward
