import styled from 'styled-components/macro'


export const GamesContainer = styled.div`


`

export const GamesRow = styled.div`
 display: flex;
 justify-content:space-between;
 align-items: center;
 gap: 20px;
 flex-wrap: wrap;
 max-width: 1000px;
margin: auto;

`

export const GamesColumn = styled.div`
    /* width: 300px; */
    height: 300px;
    /* box-shadow: 0px 0px 6px 6px rgb(0 0 0 / 5%); */
    border-radius: 10px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex:1 1 300px;
    border: 2px solid #eaeaea;
    cursor: pointer;
    
   img{
    
    width: 100px;
    height: 100px;
    object-fit: contain;
   
   }
   &:nth-child(2){
       img{
        width: 150px;
        height: 100px;
        object-fit: contain;  
       }
   }
   &:nth-child(3){
       img{
        width: 200px;
        height: 100px;
        object-fit: contain;  
       }
   }   

`

export const GamesButton = styled.button`
    background: ${({bgColor})=>bgColor ? bgColor : 'rgb(0 80 157)'};
    color: ${({color})=>color? ` ${color}`: '#fff'};
    border: none;
    height: 40px;
    width: ${({width})=> width ? width : '180px'};
    margin-top: 1rem;
    border-radius: 20px;
    cursor: ${({disabled})=>disabled ? 'not-allowed' : 'pointer'}; 
    padding: .5rem 1rem;
    border: ${({color})=>color? `1px solid ${color}`: '1px solid transparent'};

    &:hover{
        background: #fff;
        color: rgb(0 80 157);
        border:1px solid rgb(0 80 157);
    }

 
`

export const GamesTitle = styled.div`
font-size: 1.5rem;
font-weight: 600;
color: ${({color})=> color ? color : '#000'};
padding-top:1.3rem;


`

export const GameDetailsWrapper = styled.div`
padding: 1rem 1.3rem;
`

export const GameHeader = styled.div`
font-size: 1.3rem;
padding-bottom: .3rem;
`

export const GameRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap:20px;
    
    `
    

export const GameColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  svg{
    
    color: #ed9717;
    height: 35px;
    width: 35px;
    padding-bottom: 1rem;
  }
`

export const GameItem = styled.div``


export const GameSmall = styled.div`

color: #868686;
padding-bottom: .7rem;
span{
color: rgb(0 80 157);
font-weight: 600;
}
`



export const InputLabel = styled.label`
    background: ${({bgColor})=>bgColor ?bgColor :'rgb(0 80 157)'};
    color: ${({color})=>color? ` ${color}`: '#fff'};
    border: none;
    ${'' /* height: 40px; */}
    min-width: ${({width}) => width ? width:'100px'};
    margin-bottom:0.6rem;
    cursor: pointer;
    padding: 0.79rem 0rem;
    border-radius:${({radius})=> radius ? radius :'0px'};
    border: ${({color})=>color? `1px solid ${color}`: '1px solid transparent'};


`

export const FlexContainer = styled.div`
    display: flex;
    gap: 30px;
    justify-content:${({justifyContent})=>justifyContent ?justifyContent:  ''};
    align-items: center;
    margin-bottom:1rem
    


`

