
import React,{lazy, Suspense} from 'react'
import {BrowserRouter, Switch, Route} from 'react-router-dom'

import AddPlayer from './pages/dashboard/add-player';
import Draws from './pages/dashboard/profile';
import Player from './pages/dashboard/players';
import Wallet from './pages/dashboard/wallet';
import Settings from './pages/dashboard/settings';
import Support from './pages/dashboard/support';
import Transactions from './pages/dashboard/transactions';
import UserDashboard from './pages/dashboard/user-dashboard';
import ForgotPassword from './pages/onboarding/forgot-password';
import Login from './pages/onboarding/login';

import { ProtectedRoutes } from './route-guard';
import Profile from './pages/dashboard/profile';
import Games from './pages/dashboard/games';
import GuessGamesCategory from './pages/dashboard/guess-games-category';
import GuessGameCreate from './pages/dashboard/guess-game-create';
import PlayerDetails from './pages/dashboard/player-details';
import Rewards from './pages/dashboard/rewards';
import Reports from './pages/reports';
import GuessSession from './pages/dashboard/guess-game-session';
import GuessGames from './pages/dashboard/guess-game';
import TriviaActivity from './pages/dashboard/trivia-activity';
import TriviaCreate from './pages/dashboard/trivia-create';
import GameRerserve from './pages/dashboard/game-reserve.js'
import ManageReward from './pages/dashboard/manange-reward';
import AddReward from './pages/dashboard/add-reward';
import EditReward from './pages/dashboard/edit-reward';
import GuessQuestions from './pages/dashboard/guess-questions';
import CreateAvatar from './pages/dashboard/add-avatar'

// const GuessGameCreate = lazy(()=>import('./pages/dashboard/guess-game-create'));
//  const Reports = lazy(()=>import('./pages/reports'));
// const PlayerDetails = lazy(()=>import('./pages/dashboard/player-details'))
// const Rewards = lazy(()=>import('./pages/dashboard/rewards'))
// const GuessSession = lazy(()=>import('./pages/dashboard/guess-game-session'))
// const GuessGames = lazy(() => import('./pages/dashboard/guess-game'));
// const TriviaActivity = lazy(()=>import('./pages/dashboard/trivia-activity'))
// const TriviaCreate = lazy(()=>import('./pages/dashboard/trivia-create'))
// const GameRerserve = lazy(()=>import('./pages/dashboard/game-reserve.js'))
// const ManageReward = lazy(() => import('./pages/dashboard/manange-reward'));
// const AddPlayer = lazy(() => import('./pages/dashboard/add-player'));



function App(){

return (
  
  <BrowserRouter>
    <Switch>
     
         <Route exact path={'/'}   component={Login}/>
         <Route exact path={'/forgot-password'}   component={ForgotPassword}/>
         <Suspense fallback={<div>Loading...</div>}>
         <ProtectedRoutes exact path={'/dashboard'}   component={UserDashboard}/>
         <ProtectedRoutes exact path={'/'}   component={Draws}/>
         <ProtectedRoutes exact path={'/players'}   component={Player}/>
         <ProtectedRoutes exact path={'/add-player'}   component={AddPlayer}/>
         <ProtectedRoutes exact path={'/rewards'}   component={Rewards}/>
         <ProtectedRoutes exact path={'/add-avatar'}   component={CreateAvatar}/>
         <ProtectedRoutes exact path={'/add-reward'}   component={AddReward}/>
         <ProtectedRoutes exact path={'/guess-question/:id'}   component={GuessQuestions}/>
         <ProtectedRoutes exact path={'/reports'}   component={Reports}/>
         <ProtectedRoutes exact path={'/profile'}   component={Profile}/>
         <ProtectedRoutes exact path={'/manage-rewards'}   component={ManageReward}/>
         <ProtectedRoutes exact path={'/guess-categories'}   component={GuessGamesCategory}/>
         <ProtectedRoutes exact path={'/guess-games'}   component={GuessGames}/>
         <ProtectedRoutes exact path={'/guess-session'}  component={GuessSession} />
         <ProtectedRoutes exact path={'/guess-create'}  component={GuessGameCreate} />


         <ProtectedRoutes exact path={'/trivia-create'}  component={TriviaCreate} />
         <ProtectedRoutes exact path={'/trivia-activity'}  component={TriviaActivity} />
         
         <ProtectedRoutes exact path={'/player-details/:email'}   component={PlayerDetails}/>
         <ProtectedRoutes exact path={'/wallet'}  component={Wallet} />
         <ProtectedRoutes exact path={'/reserve'}  component={GameRerserve} />
         <ProtectedRoutes exact path={'/transactions'}   component={Transactions}/>
         <ProtectedRoutes exact path={'/games'}   component={Games}/>
         <ProtectedRoutes exact path={'/support'}   component={Support}/>
         <ProtectedRoutes exact path={'/settings'}   component={Settings}/>
         </Suspense> 
         <Route exact path='*'   component={Login}/> 
        
     </Switch>
  </BrowserRouter>

);

}

export default App

