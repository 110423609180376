import React, {useState, useEffect} from 'react'
import { ContentWrapper, CustomModal } from '../../component'
import DashboardContainer from '../../container/dashboardContainer'
import FeatherIcon from 'feather-icons-react'
import {useHistory} from 'react-router-dom'
import { Button, HeaderWrapper, PlayerColumn, PlayerContainer, PlayerHeader } from './players-styles/players'
import { isAuthenticated } from '../../utils/data'
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { Loading } from '../../container/loading';
import questionmark from '../../assets/images/questionmark.svg'
import trivia from '../../assets/images/questions.png'
import avatarpic from '../../assets/images/avatarpic.svg'
import { GamesButton, GamesColumn, GamesContainer, GamesRow, GamesTitle } from './games-styles/games-styles'
import { ModalColumn, ModalContent, ModalHeader, ModalRow, ModalTitle } from '../../component/custom-modal/styles/custom-modal'




const Games = () => {
  
    let history = useHistory();
    const {obj:{ token}} = isAuthenticated();
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [players, setPlayers] = useState();
    const [gameScreen, setGamesScreen] = useState(1);


    const guessCategories =()=>{
    
        history.push('/guess-create');
        setShowModal(false);
        
       
    };

    const guessGames = ()=>{
      history.push('/guess-games');
      setShowModal(false);
      
    }

 const guessSession = () =>{
   history.push('/guess-session');
   setShowModal(false);
 }

  const pushCreateTrivia = () => {
    history.push('/trivia-create')
    setShowModal(false)
  }

  const pushViewTrviaActivity = () => {
    history.push('/trivia-activity')
    setShowModal(false)
  }

  const pushCreateAvatar = ()=>{
    history.push('/add-avatar');
    setShowModal(false)
  }

    const getPlayers = async () => {

      setLoading(true)
      try{
      const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/user/auth/admin/users?role=ROLE_CLIENT`,{
        headers:{
          "Authorization" : ` Bearer ${token}`
        }
       });

       console.log(data)
    
      if(data){
        setLoading(false)
        setPlayers(data)
     
      }
      }catch(error){
              
        if (error instanceof Error) {
          if(error.message === 'Network Error'){
            toast.error('Please check your network connection and try again!');
            setLoading(false)
           }else{
          
            setLoading(false)
           }
          }
        }
    } 


    const selectGame = ()=>{
      setShowModal(true);
      setGamesScreen(1)
    }
    const selectGame1 = ()=>{
      setShowModal(true);
      setGamesScreen(2)
    }

    const selectGame2 = ()=>{
      setShowModal(true);
      setGamesScreen(3)
    }
    
    useEffect(()=>{
      const abortController = new AbortController();
      getPlayers()
      return () => {
        abortController.abort();
       }
    }, [])


    return (
        <DashboardContainer>



      {/* MODAL SESSION */}

      {showModal && (
                <CustomModal setShowModal={setShowModal} width={gameScreen===1 ? '700px': null}>
               {gameScreen  === 1 && (
                  <div>
                  <ModalHeader>GUESS GAME</ModalHeader>
                  <ModalRow>
                     <ModalColumn>
                     <img src={questionmark}     width="60px" alt='guess-game'/>
                     {/* <ModalTitle>
                         Guess Categories
                     </ModalTitle> */}
                      <GamesButton onClick={guessCategories} >Create Guess </GamesButton>
                     </ModalColumn>

                     <ModalColumn>
                     <img src={questionmark}     width="60px" alt='guess-game'/>
                     {/* <ModalTitle>
                         Guess Session
                     </ModalTitle> */}
                      <GamesButton  onClick={guessSession}>View Activities</GamesButton>
                      {/* <GamesButton  onClick={guessSession}>View </GamesButton> */}
                     </ModalColumn>

                     {/* <ModalColumn>
                     <img src={questionmark}  width="60px" alt='guess-game'/>
                     <ModalTitle>
                         Guess Game
                     </ModalTitle>
                      <GamesButton onClick={guessGames}>View </GamesButton>
                     </ModalColumn> */}
                  </ModalRow>
             </div>
               )}
               {/* {gameScreen  === 1 && (
                  <div>
                  <ModalHeader>GUESS GAME</ModalHeader>
                  <ModalRow>
                     <ModalColumn>
                     <img src={questionmark}     width="60px" alt='guess-game'/>
                     <ModalTitle>
                         Guess Categories
                     </ModalTitle>
                      <GamesButton onClick={guessCategories} >View </GamesButton>
                     </ModalColumn>

                     <ModalColumn>
                     <img src={questionmark}     width="60px" alt='guess-game'/>
                     <ModalTitle>
                         Guess Session
                     </ModalTitle>
                      <GamesButton  onClick={guessSession}>View </GamesButton>
                     </ModalColumn>

                     <ModalColumn>
                     <img src={questionmark}     width="60px" alt='guess-game'/>
                     <ModalTitle>
                         Guess Game
                     </ModalTitle>
                      <GamesButton onClick={guessGames}>View </GamesButton>
                     </ModalColumn>
                  </ModalRow>
             </div>
               )} */}

               {gameScreen ===2 && (
                  <GamesContainer>
                  <ModalHeader>AVATAR GAME</ModalHeader>
                  <ModalRow>
                     <ModalColumn>
                     <img src={avatarpic} width="80px" alt='guess-game'/>
                     <ModalTitle>
                         Create Avatar
                     </ModalTitle>
                      <GamesButton  onClick={pushCreateAvatar}>Create </GamesButton>
                     </ModalColumn>

                     <ModalColumn>
                     <img src={avatarpic} width="80px" alt='guess-game'/>
                     <ModalTitle>
                         View All Avatars
                     </ModalTitle>
                      <GamesButton>View </GamesButton>
                     </ModalColumn>    
                   
                  </ModalRow>
             </GamesContainer>
               )}

            {gameScreen ===3 && (
                  <GamesContainer>
                  <ModalHeader>TRIVIA GAME</ModalHeader>
                    <ModalContent>
                    <ModalRow>
                     <ModalColumn>
                     <img src={trivia}     width="100px" alt='guess-game'/>
                      <GamesButton onClick={pushCreateTrivia}>Create Trivia</GamesButton>
                     </ModalColumn>

                     <ModalColumn>
                     <img src={trivia}     width="100px" alt='guess-game'/>
                      <GamesButton onClick={pushViewTrviaActivity}>View Activity </GamesButton>
                     </ModalColumn>    
                   
                  </ModalRow>
                    </ModalContent>
             </GamesContainer>
               )}
                </CustomModal>
              )}



      {/* END MODAL */}





        <ContentWrapper>
               <PlayerContainer>
                    <HeaderWrapper>
                    <PlayerHeader>
                        All Games
                    </PlayerHeader>
                    {/* <Button onClick={addPlayer}><FeatherIcon icon='plus' /> Add User</Button> */}
                    </HeaderWrapper>
                    <GamesContainer>
                       <GamesRow>
                          <GamesColumn>
                          <img src={questionmark}  alt='guess-game'/>
                          <GamesTitle color='#06B4C7'>
                              Guess Game
                          </GamesTitle>
                           <GamesButton onClick={selectGame}>Manage </GamesButton>
                          </GamesColumn>

                          <GamesColumn>
                          <img src={avatarpic}  alt='guess-game'/>
                          <GamesTitle color='#123873'>
                              Avatar
                          </GamesTitle>
                           <GamesButton  onClick={selectGame1}>Manage </GamesButton>
                          </GamesColumn>
                          <GamesColumn>
                          <img src={trivia}  alt='trivia'/>
                          <GamesTitle color='#B14BE1'>
                            Trivia
                          </GamesTitle>
                          <GamesButton  onClick={selectGame2}>Manage </GamesButton>
                          </GamesColumn>
                       </GamesRow>
                    </GamesContainer>
               </PlayerContainer>
             
        </ContentWrapper>
    <Toaster position='top-center'/>
    </DashboardContainer>

    )
}

export default Games
