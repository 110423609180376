import React, {useState, useEffect, Fragment} from 'react'
import { ContentWrapper, CustomModal, OnboardingWrapper } from '../../component'
import DashboardContainer from '../../container/dashboardContainer'
import FeatherIcon from 'feather-icons-react'
import {useHistory} from 'react-router-dom'
import { Button, HeaderWrapper, Input, PlayerColumn, PlayerContainer, PlayerForm, PlayerHeader, Select } from './players-styles/players'
import { isAuthenticated } from '../../utils/data'
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { Loading } from '../../container/loading';
import { GamesButton, GamesColumn, GamesContainer, GamesRow, GamesTitle } from './games-styles/games-styles'
import { ModalColumn, ModalContent, ModalHeader, ModalRow, ModalTitle } from '../../component/custom-modal/styles/custom-modal'
import { Label } from '../../component/form/styles/form';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";




const GuessSession = () => {
  
    let history = useHistory();
    const {obj:{ token}} = isAuthenticated();
    const [showModal, setShowModal] = useState(false)
    const [allSessions, setAllSessions] = useState();
    const [updateCat, setUpdateCat] = useState(false);
    const [cat, setCat] = useState();
    const [updateDom, setUpdateDom] = useState(false);
    const [catId, setCatId] = useState();

    const [spinner, setSpinner]  = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  let start = moment(startDate).format();
  let end = moment(endDate).format();


  console.log('start', catId);
 

    const addSession =()=>{
        setShowModal(true);
        setUpdateCat(false);  
        setValues({...values, name:''});
        setCatId('')

    };

    const createSession = async(e)=>{
      e.preventDefault();
      setSpinner(true) 

      if(!name){
        setSpinner(false) 
        setValues({...values, errors:{nameError:'Name is required'}})
      }else{
        let payload ={
          name:name,
          guessCategoryId: parseInt(catId),
          startDateTime: start,
          endDateTime: end,
          
        }

        console.log("PAYLOAD", payload)
        try{
        const {data} = await axios.post(`${process.env.REACT_APP_API_URL}/games/guess-game-session`,payload,
        {
          headers:{
            "Authorization" : ` ${token}`,
            
          }
         });
  
         console.log("Guess Game Session Created", data)
      
        if(data){
          setSpinner(false) 
          setShowModal(false);
          setUpdateDom(true)
          toast.success('Session created successful!');
        }
        }catch(error){
                
          if (error instanceof Error) {
            if(error.message === 'Network Error'){
              toast.error('Please check your network connection and try again!');
              setSpinner(false) 
             }else{
              toast.error('Select a date range!')
              setSpinner(false) 
             }
            } 
          }
      }
    }

    const updateSession = (item)=>{
       setCat(item);
        setShowModal(true);
        setUpdateCat(true);
        setValues({...values, name:item.name})
        setCatId(item.guessCategoryId.id);

        setStartDate(new Date(item.startDateTime))

        setEndDate(new Date(item.endDateTime))

        console.log(  "UPDATES",item.guessCategoryId.id)

    }


    const [values, setValues] = useState({
        name:'',
        errors:{
          nameError:'',
        }
    
      });

    const [loading, setLoading] = useState(false);
 

    const getGuessSession = async () => {
 
      setLoading(true)
      try{
      const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/games/guess-game-session/?size=70`,{
        headers:{
          "Authorization" : ` ${token}`
        }
       });

       console.log("Guess Game Session", data)
    
      if(data){
        setLoading(false)
        setAllSessions(data?.obj?.guessGameSession?.sort((a,b) => new Date(a) < new Date(b) ? 1 : -1))
        
     
      }
      }catch(error){
              
        if (error instanceof Error) {
          if(error.message === 'Network Error'){
            toast.error('Please check your network connection and try again!');
            setLoading(false)
           }else{
            toast.error('System Error!')
            setLoading(false)
           }
          }
        }
    } 



    const updateGuessSession = async (e) => {
      e.preventDefault();
      setSpinner(false)  

      if(!name){
        setSpinner(false) 
        setValues({...values, errors:{nameError:'Name is required'}})
      }else{
        let payload ={
          name:name,
          guessCategoryId: parseInt(catId),
          startDateTime: start,
          endDateTime: end,
        }

        console.log('PAYLOAD', payload)
        try{
        const {data} = await axios.put(`${process.env.REACT_APP_API_URL}/games/guess-game-session`, payload,
        {
          headers:{
            "Authorization" : ` ${token}`
          }
         });
  
         console.log("Guess session update", data)
      
        if(data){
          setSpinner(false) 
          setShowModal(false);
          setUpdateDom(true)
          toast.success('Update was successful!');
        }
        }catch(error){
                
          if (error instanceof Error) {
            if(error.message === 'Network Error'){
              toast.error('Please check your network connection and try again!');
              setSpinner(false) 
             }else{
              toast.error('Internal server  Error!')
              setSpinner(false) 
             }
            }
          }
      } 
    } 



    const {name, errors} = values;
    
    const handleChange = name => e=>{  

    setValues({...values, errors:{}, [name]: e.target.value})
  }
 
    useEffect(()=>{ 
      getGuessSession()
    }, [updateDom])

    

    return (
        <DashboardContainer>



      {/* MODAL SESSION */}
        
      {showModal && (
          <>
             
                 
              <CustomModal setShowModal={setShowModal}>
              <PlayerForm style={{border: "2px solid #d3d3d3"}} padding='0 2rem'>   
                    <h3>{updateCat ? 'Update Session' : 'Add Session'}</h3>
                      <OnboardingWrapper.FormGroup  >
                      <Label>Name</Label>
                        <Input type="text" value={name}  placeholder="Name" onChange={handleChange('name')} />
                        <OnboardingWrapper.Error>{errors?.nameError}</OnboardingWrapper.Error>
                      </OnboardingWrapper.FormGroup>

                      <OnboardingWrapper.FormGroup  >
                      <Label>Guess Category</Label>
                        <Select value={catId} onChange={(e)=>setCatId(e.target.value)}>
                            <option  value=''>
                                Select Category
                            </option>
                        {allSessions?.map((session, i)=>(
                            <option key={i} value={session?.guessCategoryId?.id}>
                                {session?.guessCategoryId?.name}
                            </option>
                        ))} 
                        </Select>
                        <OnboardingWrapper.Error>{errors?.nameError}</OnboardingWrapper.Error>
                      </OnboardingWrapper.FormGroup>

                        <OnboardingWrapper.FormGroup>
                        <Label>Start Date</Label>
                        
                      <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            showTimeSelect
                            isClearable={true}
                            dateFormat="MMMM d, yyyy h:mm aa"
                        />
                        

                        </OnboardingWrapper.FormGroup>

                        <OnboardingWrapper.FormGroup>
                        <Label>End Date</Label>
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            showTimeSelect
                            endDate={endDate}
                            minDate={startDate}
                            isClearable={true}
                            dateFormat="MMMM d, yyyy h:mm aa"
                        />
                        </OnboardingWrapper.FormGroup>
                          
                       {updateCat ? <OnboardingWrapper.Button onClick={updateGuessSession}>
                      {spinner? <small>Loading...</small> : "Update "}
                      
                      </OnboardingWrapper.Button > : <OnboardingWrapper.Button onClick={createSession} >
                      {spinner? <small>Loading...</small> : "Add "}
                      
                      </OnboardingWrapper.Button>}
                       
                        
                     </PlayerForm>
                  
                   
             </CustomModal>  
     
             
          </>

      )}

      {/* END MODAL */}





        <ContentWrapper>
               <PlayerContainer>
                    <HeaderWrapper>
                    <PlayerHeader>
                        
                      Guess Sessions
                      <div onClick={()=>history.push('/games')}><FeatherIcon icon='arrow-left' />All Games</div>
                    </PlayerHeader>
                    <Button onClick={addSession}><FeatherIcon icon='plus' /> Add Session</Button>
                    </HeaderWrapper>
                    <GamesContainer>
                    <div className='players-table'>

                                  {/* {spinner && (
                                     <Loading />
                                   )}  

                                   {loading && (
                                     <Loading />
                                   )}   */}

                                   {!loading && (
                                     <table className='players'>
                                     <tbody>
                                     <tr>
                                       <th>ID</th>
                                       <th>Session Name</th>
                                        <th>Session Category</th>
                                       {/* <th>Question</th>
                                       <th>Correct Answer</th> */}
                                       <th>Start Date</th>
                                       <th>End Date </th>
                                       
                                       <th>Action</th>
                                      
                                     </tr>
                                     
                                     {allSessions?.map((session, i)=>(
                                       <tr key={i}>
                                       <td>{session.id}</td> 
                                       <td className='points'>{session.name}</td>

                                       <td className='points' >
                                         {session?.guessCategoryId?.name}</td>    
                                         
                                         
                                       <td>    

                                       <div className='user-avatar'>
                                       {moment(session.createdTime).format('LLL')}
                                         </div>
                                       </td>
                                       <td>           
                                       <div className='user-avatar'>
                                       {moment(session.endDateTime).format('LLL')}
                                         </div>
                                       </td>
              
                                    <td className='points'>
                                      <div className='cat-action-btn'>
                                        <FeatherIcon icon='edit-3' onClick={()=>updateSession(session)}/>
                                        <FeatherIcon icon='trash-2' />
                                      </div>
                                    </td>
                                       
                                      
                                       
                                     
                                     </tr>
                                     ))}

                                   
                                     
                                     </tbody>
                                   </table>
                                   )}   


                            
                    </div>
                    </GamesContainer>

                   
               </PlayerContainer>
             
        </ContentWrapper>
    <Toaster position='top-center'/>
    </DashboardContainer>

    )
}

export default GuessSession
