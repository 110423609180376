import styled from 'styled-components/macro'

export const SettingsContainer = styled.div`

  background: #fff;
  padding: 1rem 3rem;
  max-width: 600px;
  margin: auto;

  h4{
    margin-bottom: 1px;
  }
`

export const SettingsRow = styled.div`

display: flex;
gap:30px;

@media (max-width:600px){
        gap: 0px;
   
    flex-direction: column;
}
`

export const FormGroup = styled.div`
 flex: 1;
 margin-top: 1rem;

`
export const Subtitle = styled.div`
padding-top: 16px;
font-size: 14px;
font-weight: 600;

`

export const FormInput = styled.input.attrs({
  type: 'text'
})`
width: ${({width}) => width ? width : ''};

    height: 50px;
    border: 1px solid #00509d;
    box-sizing: border-box;
    border-radius: 10px;
    background: #ebf5ff;
    padding: 0 1rem;
    font-size: 14px;
    outline: none
`

export const MyFormInputWrapper = styled.div`
  min-width: ${({width}) => width ? width : '45%'};
  justify-content: space-between;
  align-items: center; 
  font-size: 14px; 
  display: flex;
  margin: 10px 0;
`