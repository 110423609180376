import React, { useState, useEffect } from "react";
import { ContentWrapper } from "../../component";
import DashboardContainer from "../../container/dashboardContainer";
import FeatherIcon from "feather-icons-react";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import {
  Button,
  HeaderWrapper,
  PlayerSearch,
  PlayerContainer,
  PlayerHeader,
  SumTotals,
  CardContainer,
  PlayerCard,
  Depo,
} from "./players-styles/players";
import {
  ReserveContainer,
  ProgressBar,
  ContentWrapperreserve,
} from "./reserve-styles/reserve";
import { isAuthenticated } from "../../utils/data";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { Loading } from "../../container/loading";
import NumberFormat from "react-number-format";
import moment from "moment";
import Fuse from "fuse.js";

const Transactions = () => {
  let history = useHistory();
  const {
    obj: { token },
  } = isAuthenticated();

  // const addPlayer =()=>{
  //     history.push('/add-player')
  const [initial, setInitial] = useState();
  const [trans, setTrans] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(20);

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const transactions = trans?.slice(indexOfFirstPost, indexOfLastPost);
  const PageCount = Math.ceil(trans?.length / 20);

  const changePage = ({ selected }) => {
    console.log(selected);
    setCurrentPage(selected + 1);
  };

  const getTransactions = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/api/v1/transaction/all`,
        {
          headers: {
            Authorization: ` Bearer ${token}`,
          },
        }
      );

      console.log(data);

      if (data) {
        setLoading(false);
        data?.obj.sort((a, b) => (new Date(a) < new Date(b) ? 1 : -1));
        setTrans(data?.obj);
        setInitial(data?.obj);
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === "Network Error") {
          toast.error("Please check your network connection and try again!");
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    getTransactions();
  }, []);

  useEffect(() => {
    if (trans) {
      const fuse = new Fuse(trans, {
        keys: ["customerEmail", "transactionReference", "transactionDate"],
      });

      const results = fuse.search(searchTerm).map(({ item }) => item);

      if (trans.length > 0 && searchTerm.length > 3 && results.length > 0) {
        setTrans(results);
      } else {
        setTrans(initial);
      }
    }
  }, [searchTerm]);

  return (
    <DashboardContainer>
      <ContentWrapper>
        <PlayerContainer>
          <HeaderWrapper>
            <PlayerHeader>Game Reserve</PlayerHeader>
          </HeaderWrapper>

          <ReserveContainer>
            <div style={{padding:'15px'}}>
                    Reserve Review
            </div>
            <div className="d-flex" style={{ gap: "17px", padding: "15px", marginTop: '-39px',
    padding: '15px' }}>
              <PlayerCard
                width={"33%"}
                style={{ display: "block", background: "#02025d" }}
              >
                <CardContainer padding={"0px 9px"}>
                  <Depo
                    padding={"76px 3px 15px 0"}
                    style={{ background: "none" }}
                  >
                    <ContentWrapperreserve>
                      <div >Total reserve</div>
                      <small >Coins:</small>
                      <small >Point:</small>
                      <div >
                        <ProgressBar>
                          <div style={{ flex: "20%", background: "#0e6e1e" }}></div>
                          <div
                            className="text-center"
                            style={{ flex: "80%", background: "#fdc500db" }}
                          >
                            redemed
                          </div>
                        </ProgressBar>
                      </div>
                    </ContentWrapperreserve>
                  </Depo>
                </CardContainer>
              </PlayerCard>
              <PlayerCard
                width={"33%"}
                style={{ display: "block", background: "#02025d" }}
              >
                <CardContainer padding={"0px 9px"}>
                  <Depo
                    padding={"76px 3px 15px 0"}
                    style={{ background: "none" }}
                  >
                    <ContentWrapperreserve>
                      <div >Coin Reserve</div>
                      <small >Coin:</small>
                      <small >Value:</small>
                      <div >
                      <ProgressBar>
                          <div style={{ flex: "20%", background: "#0e6e1e" }}></div>
                          <div
                            className="text-center"
                            style={{ flex: "80%", background: "#fdc500db" }}
                          >
                            redemed
                          </div>
                        </ProgressBar>
                      </div>
                    </ContentWrapperreserve>
                  </Depo>
                </CardContainer>
              </PlayerCard>
              <PlayerCard
                width={"33%"}
                style={{ display: "block", background: "#02025d" }}
              >
                <CardContainer padding={"0px 9px"}>
                  <Depo
                    padding={"76px 3px 15px 0"}
                    style={{ background: "none" }}
                  >
                    <ContentWrapperreserve>
                      <div >Point Reserve</div>
                      <small >Point:</small>
                      <small >Value:</small>
                      <div >
                      <ProgressBar>
                          <div style={{ flex: "50%", background: "#0e6e1e" }}></div>
                          <div
                            className="text-center"
                            style={{ flex: "50%", background: "#fdc500db" }}
                          >
                            redemed
                          </div>
                        </ProgressBar>
                      </div>
                    </ContentWrapperreserve>
                  </Depo>
                </CardContainer>
              </PlayerCard>
            </div>
          </ReserveContainer>
        </PlayerContainer>
      </ContentWrapper>
      <Toaster position="top-center" />
    </DashboardContainer>
  );
};

export default Transactions;
