import React from 'react'
import { Container, Row , Column, Form,Title, ImgWrapper,Error, FormGroup,Label,Input, Button,ResetPass, SmallText} from './styles/form'

const OnboardingWrapper = ({children, ...resProps})=> {
    return (
       <Container  {...resProps}>{children}</Container> 
    )
}

export default OnboardingWrapper

OnboardingWrapper.Row = function OnboardingWrapperRow({children, ...resProps}){
    return <Row {...resProps}>{children}</Row>
}

OnboardingWrapper.Column = function OnboardingWrapperColumn({children, ...resProps}){
    return <Column {...resProps}>{children}</Column>
}

OnboardingWrapper.Form = function OnboardingWrapperForm({children, ...resProps}){
    return <Form {...resProps}>{children}</Form>
}

OnboardingWrapper.Title = function OnboardingWrapperTitle({children, ...resProps}){
    return <Title {...resProps}>{children}</Title>
}

OnboardingWrapper.FormGroup = function OnboardingWrapperFormGroup({children, ...resProps}){
    return <FormGroup {...resProps}>{children}</FormGroup>
}

OnboardingWrapper.Label = function OnboardingWrapperLabel({children, ...resProps}){
    return <Label {...resProps}>{children}</Label>
}


OnboardingWrapper.Input = function OnboardingWrapperInput({ ...resProps}){
    return <Input {...resProps}  />
}

OnboardingWrapper.Button = function OnboardingWrapperButton({children, ...resProps}){
    return <Button {...resProps}>{children}</Button>
}

OnboardingWrapper.SmallText = function OnboardingWrapperSmallText({children, ...resProps}){
    return <SmallText {...resProps}>{children}</SmallText>
}

OnboardingWrapper.ResetPass = function OnboardingWrapperResetPass({children, ...resProps}){
    return <ResetPass {...resProps}>{children}</ResetPass>
}

OnboardingWrapper.ImgWrapper = function OnboardingWrapperImgWrapper({children, ...resProps}){
    return <ImgWrapper {...resProps}>{children}</ImgWrapper>
}

OnboardingWrapper.Error = function OnboardingWrapperError({children,...resProps}){
    return <Error {...resProps}>{children}</Error>
}

