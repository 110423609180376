import styled from 'styled-components/macro'


export const Container = styled.div`

transition: width 0.5s;
 width: ${({collapse})=> collapse ? '93%' : '85%'};
 background: #F2F4F6;
 margin-left: ${({collapse})=> collapse ? '7%' : '15%'};
 min-height: 100vh;
 transition: all .5s ;
 

 
 @media (max-width:1024px){
      /* width:70%;
      margin-left:30%; */

        transition: width 0.5s;
        width: ${({collapse})=> collapse ? '85%' : '70%'};
        background: #F2F4F6;
        margin-left: ${({collapse})=> collapse ? '15%' : '30%'};
        transition: all .5s ;
    }

@media (max-width:1000px){
  
    /* width: 73%;
    margin-left:27%; */
        width: ${({collapse})=> collapse ? '85%' : '73%'};
        background: #F2F4F6;
        margin-left: ${({collapse})=> collapse ? '15%' : '27%'};
        transition: all .5s ;
  
}

 @media (max-width:768px){
     width: 100%;
     margin-left:0%;
 }
`