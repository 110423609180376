import React, {useState, useEffect, useRef, useMemo} from 'react'
import { ContentWrapper, CustomModal, OnboardingWrapper, ProgressBar } from '../../component'
import DashboardContainer from '../../container/dashboardContainer'


import {useHistory, Link} from 'react-router-dom'
import { Button, HeaderWrapper, UserStatTotal, PlayerContainer, PlayerHeader, FilterUsers,UserStat, UserStatBox,UserStatRow, PlayerSearch, Input, Label, PlayerForm } from './players-styles/players'
import { isAuthenticated } from '../../utils/data'
import axios from 'axios'; 
import toast, { Toaster } from 'react-hot-toast'; 
import { Loading} from '../../container/loading';
import ReactTooltip from 'react-tooltip';
import ReactPaginate from 'react-paginate';
import Fuse from 'fuse.js'
import FeatherIcon from 'feather-icons-react' 




const CreateAvatar = () => {

  
    let history = useHistory();
    const {obj:{ token}} = isAuthenticated()
    const fileRef = useRef()

    const [imageFile, setImageFile] = useState();
    const [image, setImage] = useState(); 
    const [loadingProgress, setLoadingProgress] = useState(false)
    const [progress, setProgress] = useState(0);
    
    const [allAvatar, setAllAvatar] = useState();
    const [screen, setScreen] = useState(1);
    const [update, setUpdate] = useState();
    const [loading, setLoading] = useState(false);
    const [avatarId, setAvatarId] = useState(false);

    const [showModal, setShowModal] = useState(false);
  
    const [values, setValues] = useState({
        imageUrl:'',
        avatar:'',
        price:'',
        profile:'',
        errors:''
    });
    const { imageUrl, avatar,price, profile, errors } = values;
 
  console.log("IMAGES", imageUrl)

    const handleChange = (name) => (e) => {
      setValues({ ...values, errors: {}, [name]: e.target.value });
    };

    const updateGuessCat = async (e) => {
        e.preventDefault();
        setLoading(true);
    
        if (!avatar) {
          setLoading(false);
          setValues({ ...values, errors: { avatarError: "Name is required" } });
        } else {
          let payload = { 
            id: avatarId,
            name: avatar,
            imgUrl:imageUrl
          };
          console.log("Update Cate", payload);
          try {
            const { data } = await axios.put(
              `${process.env.REACT_APP_API_URL}/games/avatar`,
              payload,
              {
                headers: {
                  Authorization: ` ${token}`,
                },
              }
            );
    
            console.log("Guess Games", data);
    
            if (data) {
              setLoading(false);
              setShowModal(false);
              toast.success("Update was successful!");
              setUpdate(data)      
            }
          } catch (error) {
            if (error instanceof Error) {
              if (error.message === "Network Error") {
                toast.error("Please check your network connection and try again!");
                setLoading(false);
              } else {
                toast.error("System Error!");
                setLoading(false);
              }
            }
          }
        }
      };
    
    const addAvatar = ()=>{
        setShowModal(true);
        setScreen(1);
        setValues({...values,
          imageUrl:'',
          avatar:'',
          price:'',
          profile:'', 
         });
      }

    
    const createAvatar = async (e) => {
        e.preventDefault();
        setLoading(true);
    
        if (!avatar) {
          setLoading(false);
          setValues({ ...values, errors: { avatarError: "Name is required" } });
        }else if(!profile){
            setLoading(false);
            setValues({ ...values, errors: { profileError: "Profile is required" } });
        }else if(!price){
          setLoading(false);
          setValues({ ...values, errors: { priceError: "Price is required" } });
      }
        
        else {
          let payload = {
           
            name: avatar,
            profile: profile,
            price: price,
            imgUrl:'www.google.com' 
          };

          console.log("AVATAR PAYLOAD", payload)
          try {
            const { data } = await axios.post(
              `${process.env.REACT_APP_API_URL}/games/avatar`,payload,
              {
                headers: {
                  Authorization: `${token}`,
                },
              }
            );
    
            console.log("AVATAR", data);
    
            if (data) {
              setLoading(false);
              setShowModal(false);
              setUpdate(data)
              
              toast.success("Avatar Created  successful!");
            }
          } catch (error) {
            if (error instanceof Error) {
              if (error.message === "Network Error") {
                toast.error("Please check your network connection and try again!");
                setLoading(false);
              } else {
                toast.error("Failed to create Avatar!");
                setLoading(false);
              }
            }
          }
        }
      };
   
    const getAllAvatars = async () => {
        setLoading(true);
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_API_URL}/games/avatar?size=1000`,
            {
              headers: {
                Authorization: ` ${token}`,
              },
            }
          );
    
        
          setLoading(false);
          if (data) {
            setLoading(false);
           
            setAllAvatar(data?.obj?.avatar?.sort((a,b) => new Date(a) < new Date(b) ? 1 : -1))
           
        }
        } catch (error) {
          if (error instanceof Error) {
            if (error.message === "Network Error") {
              toast.error("Please check your network connection and try again!");
             setLoading(false);
            } else {
              toast.error("Failed to retrieve Guess games");
             setLoading(false);
          }
       }
    }
  };  

const confirmDelete = (id)=>{
  setAvatarId(id)
  setShowModal(true)
  setScreen(3)
}

const editAvatar = (avatar)=> {
  setAvatarId(avatar.id)
  setShowModal(true);
  setScreen(2);
  setValues({ ...values, avatar: avatar?.name, profile: avatar?.profile,imageUrl:avatar?.imgUrl, price: avatar?.price});
}

const deleteAvatar = async() =>{
  setLoading(true)
  try{
    const {data} = await axios.delete(`${process.env.REACT_APP_API_URL}/games/avatar/${avatarId}`,
    {
      headers: {
        Authorization: ` ${token}`,
      },
    })  
    setUpdate(data);
    setLoading(false)
    setTimeout(()=>{
      setShowModal(false)
    }, 1000)
  }
  catch(error){
    if(error instanceof Error){
      setLoading(false);
      toast.error("Please check your network connection and try again")
    }else{
      setLoading(false);
      toast.error("Failed to delete Avatar")
    }
  }
}



const handleImageUpload = async(e)=>{

  let imageFile = e.target.files[0];

  setImageFile(imageFile)

  setLoadingProgress(true)

  console.log("IMAGE", imageFile)
  if(e.target.files[0].size > 1000000){

    setLoadingProgress(false);
  toast.error(`File size should be less than 1MB`);
 
 }else{
  const cloudName = 'doumwledk';
  const imageData = new FormData();
  imageData.append("file", imageFile);
  imageData.append('upload_preset', 'gajtmsrs');
  try{
     
      let {data} =  await axios.post(`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`, imageData,
      {           
      onUploadProgress: (e)=>{      
          let percent = Math.floor((100 * e.loaded) /e.total)
          if(percent <= 100){
              setProgress(percent)
          }
         }
         }
      );
      toast.success('Image upload was successful!');
        
        setValues({...values, imageUrl:data?.url});  
        setTimeout(()=>{
          setProgress(0) ;
        }, 5000)   
    }catch(err){
      // console.log(err)
      setLoadingProgress(false)
      toast.error('Image Upload failed, Try again');
    }
 }
}

const uploadFile = ()=>{
  fileRef.current.click();
}

useEffect(()=>{
  getAllAvatars();
}, [update])



    return (
        <DashboardContainer>
        {showModal &&  (
        <CustomModal setShowModal={setShowModal}>
          <PlayerForm>
          
             {screen=== 1 && (
               <div>
               <h3>{"Create Avatar"}</h3>
              <OnboardingWrapper.FormGroup>      
                <Label>Name</Label>
                <Input
                  type="text"
                  value={avatar}
                  placeholder="Avatar name"
                  onChange={handleChange("avatar")}
                />
               
                <OnboardingWrapper.Error>
                  {errors?.avatarError}
                </OnboardingWrapper.Error>
              </OnboardingWrapper.FormGroup>
              <OnboardingWrapper.FormGroup>
              <Label>Profile</Label>
              <Input
                  type="text"
                  value={profile}
                  placeholder="Avatar profile"
                  onChange={handleChange("profile")}
                />
               
              </OnboardingWrapper.FormGroup>
              <OnboardingWrapper.FormGroup>
              <Label>Price</Label>
             
                 <Input
                  type="number"
                  value={price}
                  placeholder="Avatar price"
                  onChange={handleChange("price")}
                />
              </OnboardingWrapper.FormGroup>
              
              <OnboardingWrapper.FormGroup >
              <Label>Upload Image</Label>   
              <input type="file" ref={fileRef} onChange={handleImageUpload}  accept='image/*' />
           
                <br />
              {progress !==0 && (
                    <ProgressBar  completed={progress}/>
                   
              )}
                </OnboardingWrapper.FormGroup>
              <OnboardingWrapper.Button onClick={createAvatar}>
                  {loading ? <small>Loading...</small> : "Create "}
                </OnboardingWrapper.Button>
               </div>
             )}

            {screen==2  && (
                <div>
                <h3>{"Update Avatar"}</h3>
               <OnboardingWrapper.FormGroup>
               
                 <Label>Name</Label>
                 <Input
                   type="text"
                   value={avatar}
                   placeholder="Avatar name"
                   onChange={handleChange("avatar")}
                 />
 
                 <OnboardingWrapper.Error>
                   {errors?.avatarError}
                 </OnboardingWrapper.Error>
               </OnboardingWrapper.FormGroup>

               <OnboardingWrapper.FormGroup>
               
               <Label>Profile</Label>
               <Input 
                 type="text"
                 value={profile}
                 placeholder="Avatar profile"
                 onChange={handleChange("profile")}
               />

               <OnboardingWrapper.Error>
                 {errors?.avatarError}
               </OnboardingWrapper.Error>
             </OnboardingWrapper.FormGroup>
             <OnboardingWrapper.FormGroup>
               
               <Label>Price</Label>
               <Input
                 type="number"
                 value={price}
                 placeholder="Avatar price"
                 onChange={handleChange("price")}
               />

               <OnboardingWrapper.Error>
                 {errors?.avatarError}
               </OnboardingWrapper.Error>
             </OnboardingWrapper.FormGroup>
               <OnboardingWrapper.FormGroup >
              <Label>Upload Image</Label>   
              <input type="file" ref={fileRef} onChange={handleImageUpload}   accept='image/*' />
          
                <br />
              {progress !==0 && (
                    <ProgressBar  completed={progress}/>
                   
              )}
                </OnboardingWrapper.FormGroup>

               <OnboardingWrapper.Button onClick={updateGuessCat}>
                   {loading ? <small>Loading...</small> : "Update"}
                 </OnboardingWrapper.Button>
                </div>
            )} 

            {screen === 3 && (
              <div  className='delete-cat-info'>
              <FeatherIcon icon='info' color='#ed9717'  size='40'/>
             <h3>Are you sure</h3>
             <small>You want to delete this Avatar?</small>
              <div style={{display:'flex'}}>
              <OnboardingWrapper.Button bgColor='red' width='150px' onClick={deleteAvatar}>
               {loading ? <small>Deleting...</small> : "Yes "}
             </OnboardingWrapper.Button>
             <OnboardingWrapper.Button onClick={()=>setShowModal(false)}   bgColor='#ccc' width='150px'>
               {"Cancel "}
             </OnboardingWrapper.Button>
              </div>
           </div>
            )}

          </PlayerForm>
        </CustomModal>
      )}


        <ContentWrapper>
                <PlayerContainer> 
                    <HeaderWrapper>
                    <PlayerHeader>
                       Create Avatar
                    </PlayerHeader>
                    {/* <Button><FeatherIcon icon='plus' /> Add Guess</Button> */}
                    </HeaderWrapper>
                    {loading && (
                        <Loading /> 
                    )}
                     {!loading && (
                           <div className='guess-card'>
                           <div className='guess-title'>Select Avatar </div><br/>
                            <div className='guess-body'>    
                                <div className='guess-card-item'>
                           
                                       <FeatherIcon  icon='plus' size='40px' onClick={addAvatar}/>
                                </div>
                                {allAvatar?.map((avatar,i)=>( 
                                     
                                        <div key={i} className='guess-card-items' style={{position:'relative'}}>
                                          
                                             <div className='guess-card-inner'>
                                           
                                                 <img src={avatar?.imgUrl}  width='30%' />
                                                 <div style={{fontWeight:'600', textTransform:'uppercase'}}>{avatar.name}</div>
                                             </div>
                                         

                                           <div className='action-icon'>
                                                <FeatherIcon icon="edit-3" onClick={()=>editAvatar(avatar)} />
                                                <FeatherIcon icon='eye' color='#00509d'/>
                                                 <FeatherIcon icon="trash-2" color='red' onClick={()=>confirmDelete(avatar.id)}/>
                                                 
                                                </div>
                                               
                                            </div>
                                          ))}                   
                                 </div>  
                            </div>
                           
                     )}

                         
                    <ReactTooltip  /> 
                  </PlayerContainer>
             
            
        </ContentWrapper>
        
    <Toaster position='top-center'/>
    </DashboardContainer>

    )
}

export default CreateAvatar
